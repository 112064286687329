import clsx from 'clsx';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useNavigate,useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { requestOtp, verifyOtpForAccountVerification } from '../core/_requests';
import { Value } from 'sass';

export function VerifyAccount() {
    const { email } = useParams();
    
    // const email = location.state?.email;  // Retrieve the passed email
    const [loading, setLoading] = useState(false);
    const [hasErrors, setHasErrors] = useState<string | undefined>(undefined); // Holds specific error messages
    const [otpSent, setOtpSent] = useState(false);
    const [submissionSuccess, setSubmissionSuccess] = useState(false);
    const navigate = useNavigate();
    const initialValues = { email: email ||'', otp: '' };

    const emailValidationSchema = Yup.object().shape({
        email: Yup.string()
            .email('Invalid email format')
            .min(3, 'Minimum 3 characters')
            .max(50, 'Maximum 50 characters')
            .required('Email is required'),
    });

    const otpAndPasswordValidationSchema = Yup.object().shape({
        otp: Yup.string().required('OTP is required'),
    });

    const validationSchema = otpSent ? otpAndPasswordValidationSchema : emailValidationSchema;

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values, { setSubmitting }) => {
            if (!otpSent) {
                // Request OTP
                setLoading(true);
                requestOtp(values.email)
                    .then((res) => {
                        setOtpSent(true);
                        setHasErrors(undefined);  // Reset errors
                        setLoading(false);
                    })
                    .catch(() => {
                        setHasErrors('Invalid email. Please use the correct email to activate.');
                        setLoading(false);
                    });
            } else {
                // Verify OTP
                setLoading(true);
                verifyOtpForAccountVerification(values.email, values.otp)
                    .then(() => {
                        setSubmissionSuccess(true);
                        setLoading(false);
                        setTimeout(() => {
                            navigate('/auth');
                        }, 5000);
                    })
                    .catch(() => {
                        setHasErrors('Invalid OTP. Please check and try again.');
                        setLoading(false);
                    });
            }
        },
    });

    // Function to clear error messages when the user starts typing again
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setHasErrors(undefined);
        formik.handleChange(e);
    };

    return (
        <form className="form w-100" noValidate onSubmit={formik.handleSubmit}>
            <div className="text-center mb-10">
                <h1 className="text-dark fw-bolder mb-3">Please Verify Your Account</h1>
                <div className="text-gray-500 fw-semibold fs-6">
                    {!otpSent
                        ? 'Click on Submit button to activate your account.'
                        : 'OTP has been sent to your email. Enter the OTP for Account Activation.'}
                </div>
                {!otpSent && loading ? 'Sending OTP...' : ''}
                <div>{!submissionSuccess && loading && <div className="spinner-border text-success" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>}</div>
            </div>

            {/* Display error messages */}
            {hasErrors && (
                <div className="mb-lg-15 alert alert-danger">
                    <div className="alert-text font-weight-bold">{hasErrors}</div>
                </div>
            )}

            {/* Email Field */}
            {!otpSent && (
                <div className="fv-row mb-8">
                    <label className="form-label fw-bolder text-gray-900 fs-6">Email</label>
                    <input
                        type="email"
                        {...formik.getFieldProps('email')}
                        onChange={handleChange} // Clear error on input change
                        className={clsx('form-control bg-transparent', {
                            'is-invalid': formik.touched.email && formik.errors.email,
                            'is-valid': formik.touched.email && !formik.errors.email,
                        })}
                        value={email}
                        disabled
                    />
                    {formik.touched.email && formik.errors.email && (
                        <div className="fv-help-block">
                            <span role="alert">{formik.errors.email}</span>
                        </div>
                    )}
                </div>
            )}

            {/* OTP Field and Spinner */}
            {otpSent && (
                <div className="fv-row mb-8">
                    <label className="form-label fw-bolder text-gray-900 fs-6">Enter OTP</label>
                    <input
                        type="text"
                        disabled={submissionSuccess}
                        {...formik.getFieldProps('otp')}
                        onChange={handleChange} // Clear error on input change
                        className={clsx('form-control bg-transparent', {
                            'is-invalid': formik.touched.otp && formik.errors.otp,
                            'is-valid': formik.touched.otp && !formik.errors.otp,
                        })}
                    />
                    {formik.touched.otp && formik.errors.otp && (
                        <div className="fv-help-block">
                            <span role="alert">{formik.errors.otp}</span>
                        </div>
                    )}
                </div>
            )}

            {/* Buttons */}
            <div className="d-flex flex-wrap justify-content-center pb-lg-0">
                {!otpSent && (
                    <button
                        type="submit"
                        className="btn btn-success me-4"
                        disabled={formik.isSubmitting || !formik.isValid}
                    >
                        {!loading ? (
                            <span className="indicator-label d-flex align-items-center">Submit</span>
                        ) : (
                            <span className="indicator-progress" style={{ display: 'block' }}>
                                Submitting..
                            </span>
                        )}
                    </button>
                )}

                {/* Show "Verifying" spinner along with OTP input */}
                {otpSent && (
                    <button type="submit" className="btn btn-success" disabled={submissionSuccess}>
                        {!loading ? (
                            !submissionSuccess ? (
                                <span className="indicator-label d-flex align-items-center">Verify OTP</span>) :
                                (
                                    <span className="indicator-progress" style={{ display: 'block' }}>
                                        Verified
                                    </span>
                                )
                        ) : (

                            <span className="indicator-progress" style={{ display: 'block' }}>
                                Verifying..
                            </span>
                        )
                        }
                    </button>
                )}
            </div>

            {/* Success message */}
            {submissionSuccess && (
                <div className="alert alert-success mt-5" role="alert">
                    <strong>Account verified successfully!</strong>
                    <p>You will be redirected to the login page in 5 seconds.</p>
                </div>
            )}
        </form>
    );
}
