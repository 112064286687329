import clsx from "clsx";
import { useState } from "react";
import { KTIcon, toAbsoluteUrl } from "../../../helpers";
import {
  HeaderNotificationsMenu,
  HeaderUserMenu,
  Search,
  ThemeModeSwitcher
} from "../../../partials";
import { useLayout } from "../../core";
import { Logout, AuthPage, useAuth } from "../../../../app/modules/auth";
import { Link, Navigate, Route } from "react-router-dom";
import { Button } from "react-bootstrap";

const itemClass = "ms-1 ms-lg-3";
const btnClass =
  "btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px";
const userAvatarClass = "symbol-35px symbol-md-40px";
const btnIconClass = "fs-1";

const Navbar = () => {
  const { currentUser, logout } = useAuth();

  const [showDropdown, setShowDropdown] = useState<string>();

  const toggleDropdown = (dropDown: string) => {
    setShowDropdown(showDropdown === dropDown ? '' : dropDown); // Toggles the dropdown for the corresponding file_url
  };

  const handleButtonClick = (dropDown: string) => {
    console.log(dropDown)
    toggleDropdown(dropDown); // Toggle the dropdown visibility
  };
  const { config } = useLayout();
  return (
    <div className="app-navbar flex-shrink-0">
      {/* <div className={clsx("app-navbar-item align-items-stretch", itemClass)}>
        <Search />
      </div> */}

      {/* <div className={clsx("app-navbar-item", itemClass)}>
        <div id="kt_activities_toggle" className={btnClass}>
          <KTIcon iconName="chart-simple" className={btnIconClass} />
        </div>
      </div> */}

      {currentUser &&
        <span style={{ position: 'relative', display: 'inline-block' }}>
          <button

            onClick={() =>
              handleButtonClick('drop')

            }
            type='button'
            className='btn btn-primary btn-lg  me-3 my-4 w-180px '

          >
            Sample Files
            <KTIcon iconName='down' className='fs-5 m-0' />
          </button>
          {showDropdown === 'drop' && (
            <div
              style={{ position: 'absolute', top: 'calc(100% + 5px)', left: 0, zIndex: 10 }}
              className='btn btn-light btn-sm fw-bold fs-7 '
            >
              <div className='menu-item px-5'>
                <a target='_blank' href='https://drive.google.com/drive/folders/1s20nRLKR0AmdtTa0q4zUKq0OfCU_el0c?usp=drive_link'
                  className="menu-link px-5"
                  onClick={(e) => { setShowDropdown(""); }}>
                  Sample Documents
                </a>
              </div>
              <div className='menu-item px-5'>
                <Link to={'/samplequestion'} className='menu-link px-5'

                  onClick={(e) => { setShowDropdown(""); }}>
                  Sample Questions
                </Link>
              </div>
            </div>
          )}
        </span>
      }

      {
        currentUser &&
        <div className={clsx("app-navbar-item", itemClass)}>
          <Link to={'/statistics/invoice'}>
            <div className={'btn btn-lg btn-icon btn-primary'} title="Analytics">
              <img src={toAbsoluteUrl("/media/custom-icon/graph-icon.png")} alt="Analytics" height={30} />
              {/* <KTIcon iconName="chart-simple" className={btnIconClass} /> */}
            </div>
          </Link>
        </div>}

      {/* <div className={clsx("app-navbar-item", itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
          className={btnClass}
        >
          <KTIcon iconName="element-plus" className={btnIconClass} />
        </div>
        <HeaderNotificationsMenu />
      </div> */}

      {/* {currentUser?.chatgpt_service == 1 && <div className={clsx("app-navbar-item", itemClass)}> */}
      {/* <div
          className={clsx("position-relative", btnClass)}
          id="kt_drawer_chat_toggle"
        >
          <KTIcon iconName="message-text-2" className={btnIconClass} />
          <span className="bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink" />
        </div> */}
      {/* </div>} */}

      {/* <div className={clsx("app-navbar-item", itemClass)}>
        <ThemeModeSwitcher
          toggleBtnClass={clsx("btn-active-light-primary btn-custom")}
        />
      </div> */}

      <div className={clsx("app-navbar-item", itemClass)}>
        {currentUser ? (
          <>
            <div
              className={clsx("cursor-pointer symbol", userAvatarClass)}
              data-kt-menu-trigger="{default: 'click'}"
              data-kt-menu-attach="parent"
              data-kt-menu-placement="bottom-end"
            >
              <img src={toAbsoluteUrl("/media/avatars/300-1.jpg")} alt="" />
            </div>
            <HeaderUserMenu />
            {/* <div className="menu-item px-5">
              <a onClick={logout} className="menu-link px-5">
                Sign Out
              </a>
            </div> */}
          </>
        ) : (
          <>
            <Link
              className="menu-item px-5"
              to="auth/"
            >
              Login
            </Link>
            <Link
              className="menu-item px-5"
              to="auth/registration"
            >
              Register
            </Link>

            <button
              className="btn btn-primary btn-lg px-7 py-3 fw-bold fs-2 d-flex align-items-center"
            >
              Try It Out
              <KTIcon iconName="arrow-up-right" className="ms-3 fs-1 fw-bold" />
            </button>
          </>
        )}
        {/* <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} alt='' />
        </div>
        <HeaderUserMenu /> */}
        {/*  */}
      </div>

      {config.app?.header?.default?.menu?.display && (
        <div
          className="app-navbar-item d-lg-none ms-2 me-n3"
          title="Show header menu"
        >
          <div
            className="btn btn-icon btn-active-color-primary w-35px h-35px"
            id="kt_app_header_menu_toggle"
          >
            <KTIcon iconName="text-align-left" className={btnIconClass} />
          </div>
        </div>
      )}
    </div>
  );
};

export { Navbar };
