import { useState } from "react";

import { Blocks } from "react-loader-spinner";
import { useParams } from "react-router-dom";
import { KTIcon } from "../../../_metronic/helpers";
import { DriveTable } from "../../../_metronic/partials/widgets/tables/DriveTable";
import { useAuth } from "../../modules/auth";

// import { saveAs } from 'file-saver';

type Props = {};

type fileType = Array<string>;

const bgList = ["bg-success", "bg-primary", "bg-warning"];

type MyObject = {
    [key: string]: string[];
};

type GDriveProcessingStats = {
    "Unprocessed Resumes": {
        daily: number;
        weekly: number;
        monthly: number;
    };
    "Processed Resumes": {
        daily: number;
        weekly: number;
        monthly: number;
    };
};

interface TeamMemberFormObject {
    username: string;
    email: string;
    password: string;
    organizationId?: number;
    assign_to: string;
    type: string;
}

interface TeamMemberObject {
    username: string;
    email: string;
    password: string;
    organizationId: number;
    type: string;
    organization_name: string
}

interface TeamAnalyticsObject {
    assign_to: string,
    processed_count: number,
    unprocessed_count: number,
    total_count: number,
    accepted_count: number,
    rejected_count: number,
    pending_count: number
}

interface GDriveObject {
    id: number,
    source_folder: string,
    destination_folder: string,
    useremail: string,
    username: string,
    assign_to: string,
    template: string,
    organisation: number,
    source_folder_name: string,
    destination_folder_name: string
}

export const DriveAnalytics = ({ }: Props) => {
    const { currentUser } = useAuth();
    const { doctype } = useParams()

    const initialFormData: GDriveObject = {
        username: currentUser?.username ? currentUser.username : '',
        useremail: currentUser?.email ? currentUser.email : '',
        source_folder: '',
        destination_folder: '',
        source_folder_name: '',
        destination_folder_name: '',
        assign_to: '',
        template: '',
        organisation: currentUser?.organizationId ? currentUser.organizationId : 0,
        id: 0
    };

    const [fetching, setFetching] = useState<boolean>(false)
    const [processing, setProcessing] = useState<boolean>(false)
    const [update, setUpdate] = useState<boolean>(false)
    const [fetchAnalytics, setFetchAnalytics] = useState<boolean>(false)

    const [responseMessage, setResponseMessage] = useState("");
    const [members, setMembers] = useState<TeamMemberObject[]>();
    const [gdriveInfo, setGDriveInfo] = useState<GDriveObject[]>();
    const [gdriveCountAnalytics, setGdriveCountAnalytics] = useState<GDriveProcessingStats>()

    const [formData, setFormData] = useState<GDriveObject>(initialFormData);
    const [memberAnalytics, setMemberAnalytics] = useState<TeamAnalyticsObject>({
        assign_to: '',
        processed_count: 0,
        unprocessed_count: 0,
        total_count: 0,
        accepted_count: 0,
        rejected_count: 0,
        pending_count: 0
    });

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;

        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleCreateDriveSource = () => {
        alert("Dsiabled G Drive Automation")

    };

    const setGDriveAnalytics = (token: string[]) => {
        alert("Dsiabled G Drive Automation")
    }

    return (
        <div>

            {/* Update / Create GDrive */}
            <div
                className="modal fade"
                id="kt_modal_team"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered mw-650px">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2 className="fw-bolder">Assign Folders and Template to Team Member</h2>
                            <div
                                className="btn btn-sm btn-icon btn-active-color-primary"
                                data-bs-dismiss="modal"
                                onClick={(e) => setUpdate(false)}
                            >
                                <KTIcon iconName="cross" className="fs-1" />
                            </div>
                        </div>
                        <div className="modal-body scroll-y">
                            <div
                                className={`card card-xl-stretch`}
                                style={{ maxHeight: "60vh", overflowY: "scroll" }}
                            >
                                <div className="row">
                                    <div className="col-12">
                                        <div className="row mb-6">
                                            <label className="col-lg-3 col-form-label fw-bold fs-6">
                                                <span className="">Assigned Member</span>
                                            </label>

                                            <div className="col-lg-9 fv-row">
                                                <select
                                                    className="form-select form-select-solid"
                                                    data-kt-select2="true"
                                                    data-placeholder="Select option"
                                                    data-allow-clear="true"
                                                    value={formData?.assign_to}
                                                    name="assign_to"
                                                    onChange={handleInputChange}
                                                >
                                                    <option value="">---Select Member---</option>
                                                    {
                                                        members?.map((member) => (
                                                            <option value={member.email}>{member.email}</option>
                                                        ))
                                                    }
                                                </select>

                                            </div>
                                        </div>

                                        <div className="row mb-6">
                                            <label className="col-lg-3 col-form-label fw-bold fs-6">
                                                <span className="">Source Folder</span>
                                            </label>

                                            <div className="col-lg-9 fv-row">
                                                <input
                                                    type="text"
                                                    // className="form-select form-select-solid"
                                                    className='form-control'
                                                    value={formData?.source_folder}
                                                    onChange={handleInputChange}
                                                    name="source_folder"
                                                />
                                                <small className="mx-2">Please provide the folder link</small>
                                            </div>
                                        </div>

                                        <div className="row mb-6">
                                            <label className="col-lg-3 col-form-label fw-bold fs-6">
                                                <span className="">Destination Folder</span>
                                            </label>

                                            <div className="col-lg-9 fv-row">
                                                <input
                                                    type="text"
                                                    // className="form-select form-select-solid"
                                                    className='form-control'
                                                    value={formData?.destination_folder}
                                                    onChange={handleInputChange}
                                                    name="destination_folder"
                                                />
                                                <small className="mx-2">Please provide the folder link</small>
                                            </div>
                                        </div>

                                        <div className="row mb-6">
                                            <label className="col-lg-3 col-form-label fw-bold fs-6">
                                                <span className="">Template</span>
                                            </label>

                                            <div className="col-lg-9 fv-row">
                                                <select
                                                    className="form-select form-select-solid"
                                                    data-kt-select2="true"
                                                    data-placeholder="Select option"
                                                    data-allow-clear="true"
                                                    value={formData?.template}
                                                    name="template"
                                                    onChange={handleInputChange}
                                                >
                                                    <option value="">---Select Template---</option>
                                                    <option value="Template1">Template1</option>
                                                    <option value="Template2">Template2</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="my-2 px-2 text-center d-flex justify-content-end">
                                <button
                                    className="btn btn-lg btn-success"
                                    data-bs-dismiss="modal"
                                    onClick={handleCreateDriveSource}
                                >
                                    {!processing && <span className="indicator-label">{update ? "Update" : "Save"}</span>}
                                    {processing && (
                                        <span className="indicator-progress" style={{ display: "block" }}>
                                            Please wait...{" "}
                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span>
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Grive Folder Analytics */}
            <div
                className="modal fade"
                id="kt_modal_team_analytics"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered mw-450px">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2 className="fw-bolder">Team Member Analytics</h2>
                            <div
                                className="btn btn-sm btn-icon btn-active-color-primary"
                                data-bs-dismiss="modal"
                            >
                                <KTIcon iconName="cross" className="fs-1" />
                            </div>
                        </div>
                        <div className="modal-body scroll-y">
                            <div
                                className={`card card-xl-stretch`}
                                style={{ maxHeight: "60vh", overflowY: "scroll" }}
                            >
                                <div className="row">
                                    {
                                        fetchAnalytics ?
                                            <Blocks
                                                visible={true}
                                                height="80"
                                                width="80"
                                                ariaLabel="blocks-loading"
                                                wrapperStyle={{}}
                                                wrapperClass="blocks-wrapper"
                                            /> :
                                            <div className="col-12">
                                                <div className="row mb-2 align-items-end">
                                                    <div className="col-lg-8 col-form-label fw-bold fs-6">
                                                        <span className="">Source Documents Count</span>
                                                    </div>

                                                    <div className="col-lg-4">
                                                        <p>{gdriveCountAnalytics?.['Unprocessed Resumes'].daily}</p>
                                                    </div>
                                                </div>
                                                <div className="row mb-2 align-items-end">
                                                    <div className="col-lg-8 col-form-label fw-bold fs-6">
                                                        <span className="">Destination Documents Count</span>
                                                    </div>

                                                    <div className="col-lg-4 fv-row">
                                                        <p>{gdriveCountAnalytics?.['Processed Resumes'].daily}</p>
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card card-xl-stretch mb-xl-8 bg-light" style={{ borderRadius: '0px', minHeight: '90vh' }}>
                {/* begin::Header */}
                <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bold text-dark mb-2">
                            {" "}
                            {currentUser?.organization_name} Team Member’s Folders
                        </span>
                        <span className="text-muted mt-1 fw-semibold fs-7">
                            Athenticate New <strong>Drive Folder </strong>
                        </span>
                    </h3>
                </div>
                {/* end::Header */}


                {/* begin: Button Row */}
                <div className="row">
                    <div
                        className="d-flex justify-content-end my-1"
                        data-kt-user-table-toolbar="base"
                    >
                        <div className="px-5">
                            <button
                                type="button"
                                className="btn btn-primary me-3 my-3 w-180px "
                                data-bs-toggle="modal"
                                data-bs-target="#kt_modal_team"
                            // onClick={(e) => setCloseModal(false)}
                            >
                                <KTIcon iconName="plus" className="fs-3" />
                                Assign Folders and Template to Team Member
                            </button>
                        </div>
                    </div>
                </div>
                {/* end: Button Row */}

                <div className="row d-flex justify-content-between position-relative mx-5">
                    <div className="col-sm-12 col-md-12">
                        <div
                            className={`card card-xl-stretch mb-5 mb-xl-12`}
                            style={{ maxHeight: "70vh", overflowY: "scroll" }}
                        >
                            {(members && members.length > 0) && (gdriveInfo && gdriveInfo.length > 0) ? (
                                <DriveTable className='mb-5 mb-xl-8' userDetails={members} gdriveInfo={gdriveInfo} setGDriveAnalytics={setGDriveAnalytics} setUpdate={setUpdate} setFormData={setFormData} />
                            ) : (
                                <div className="row align-items-center">
                                    {
                                        fetching ?
                                            <Blocks
                                                visible={true}
                                                height="80"
                                                width="80"
                                                ariaLabel="blocks-loading"
                                                wrapperStyle={{}}
                                                wrapperClass="blocks-wrapper"
                                            />
                                            : <div className="card-body pt-5">
                                                <p className="fs-3 fw-bold text-center">No Source Folder Created</p>
                                            </div>
                                    }
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
