import ApexCharts, { ApexOptions } from 'apexcharts'
import React, { useEffect, useRef, useState } from 'react'
import { Blocks } from 'react-loader-spinner'
import { useParams } from 'react-router-dom'
import { getCSS, getCSSVariableValue } from '../../../_metronic/assets/ts/_utils'
import { KTIcon } from '../../../_metronic/helpers'
import { useThemeMode } from '../../../_metronic/partials'
import { datestatistic, memberStatistic, filesByUser } from '../../api'
import { useAuth } from '../../modules/auth'
import { MemberData } from '../../modules/statistics/StatisticsModel'
import clsx from "clsx";
import { TeamMemberGraph } from '../../modules/statistics/TeamMemberGraph'


type Props = {
    className: string;

}

type MyObject = {
    data: {
        processed_count: number;
        unprocessed_count: number;
        total_count: number;
        average_time: string;
        accepted_count: number;
        rejected_count: number;
        pending_count: number;
        download_count: number;
    };
};

type DailyCount = {
    date: string;
    count: number;
}
interface ResponseItem {
    id: number;
    upload_date: string; // ISO date string
    file_original_name: string;
    approval: string;
    processed: number; // assuming processed is 1 for processed and 0 for unprocessed
    feedback: string | null; // feedback can be null
}
const Statistics: React.FC<Props> = ({ className }) => {

    const { doctype } = useParams()

    const chartRef = useRef<HTMLDivElement | null>(null)
    const { currentUser } = useAuth();
    const [tag, setTag] = useState<string>(doctype ? doctype : "invoice")
    const [maxDate, setMaxDate] = useState<string>()
    const [startDate, setStartDate] = useState<Date>(new Date(new Date().setDate(new Date().getDate() - 10)))
    const [endDate, setEndDate] = useState<Date>(new Date())
    const [dateList, setDateList] = useState<string[]>()
    const [countList, setCountList] = useState<number[]>()
    const [processCountList, setProccessCountList] = useState<number[]>()
    const [unprocessCountList, setUnprocessCountList] = useState<number[]>()
    const [processing, setProcessing] = useState<boolean>(false)
    const [interval, setInterval] = useState<string>()
    const [selectedDateAnalytics, setSelectedDateAalytics] = useState(new Date());
    const [responseTable, setResponseTable] = useState<ResponseItem[]>([])

    const [membersProgressAnalytics, setMembersProgressAnalytics] = useState<MemberData[]>()
    const [view, setView] = useState<'graph' | 'table'>('graph'); // Default view is set to 'graph'
    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const documentsPerPage = 5;
    const [statisticsData, setStatisticsData] = useState<MyObject>({
        data: {
            processed_count: 0,
            unprocessed_count: 0,
            total_count: 0,
            average_time: '',
            accepted_count: 0,
            rejected_count: 0,
            pending_count: 0,
            download_count: 0,
        }
    });

    const { mode } = useThemeMode()

    const convertDateFormat = (dt: Date) => {
        const currentDate = dt

        // Format the updated date as YYYY-MM-DD
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');

        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate
    }

    useEffect(() => {
        if (view === 'graph' && chartRef.current) {
            const chart = refreshChart();
            console.log("charting");

            return () => {
                if (chart) {
                    chart.destroy();
                }
            };
        }
    }, [view, chartRef, mode, dateList, processCountList, unprocessCountList])

    const refreshChart = () => {
        console.log(dateList)
        console.log(countList)
        console.log(processCountList)
        console.log(unprocessCountList)
        if (!chartRef.current) {
            return
        }
        console.log(dateList)
        const height = parseInt(getCSS(chartRef.current, 'height'))

        if (dateList && dateList?.length > 0 && countList && countList?.length > 0 && processCountList && processCountList?.length > 0 && unprocessCountList && unprocessCountList?.length > 0) {
            const chart = new ApexCharts(
                chartRef.current,
                getChartOptions(
                    height,
                    countList,
                    dateList,
                    processCountList,
                    unprocessCountList,
                )
            )
            if (chart) {
                chart.render();
            }
            return chart
        }

        return
    }

    // Handle page change
    const handlePageChange = (page: number) => {
        if (page > 0 && page <= totalData) {
            setCurrentPage(page);
        }
    };

    const selectedDate = (dt: Date, type: string) => {
        if (dt && type == "start") {
            setStartDate(dt)
        } else if (dt && type == "end") {
            setEndDate(dt)
        }
    }


    const toggleView = (selectedView: 'graph' | 'table') => {
        setView(selectedView);
        if (selectedView == 'graph') {
            console.log("hii")
            refreshChart()
        }
    }
    // Calculate the total number of pages
    const totalData = Math.ceil(responseTable.length / documentsPerPage);

    // Slice the documents to be displayed on the current page
    const currentResponse = responseTable.slice((currentPage - 1) * documentsPerPage, currentPage * documentsPerPage);

    const handleDateChangeAnalytics = (date: any) => {
        setSelectedDateAalytics(date)
    }

    const formatAverageTime = (timeString: string): JSX.Element => {
        const [minutes, seconds] = timeString.split(':');
        return (
            <>
                {minutes} <span className="fs-6"> min</span>
                <span> </span>
                {seconds} <span className="fs-6">  sec</span>
            </>
        );
    };

    useEffect(() => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Adding 1 because month is zero-based
        const day = String(currentDate.getDate()).padStart(2, '0');

        const formattedDate = `${year}-${month}-${day}`;
        setMaxDate(formattedDate);
    }, [])

    useEffect(() => {
        if (tag && tag!='Select Document Type' && startDate && endDate) {
            setProcessing(true)
            console.log(startDate, endDate)
            let stDt = convertDateFormat(startDate)
            let edDt = convertDateFormat(endDate)
            datestatistic({ userName: currentUser?.username, useremail: currentUser?.email, tag: tag, created_at: [stDt, edDt], })
                .then((res) => {
                    console.log(res)

                    if (res) {
                        setStatisticsData({
                            data: {
                                processed_count: Number(res?.processed_count) || 0,
                                unprocessed_count: Number(res?.unprocessed_count) || 0,
                                total_count: Number(res?.total_count) || 0,
                                average_time: String(res?.average_time) || '',
                                accepted_count: Number(res?.accepted_count) || 0,
                                rejected_count: Number(res?.rejected_count) || 0,
                                pending_count: Number(res?.pending_count) || 0,
                                download_count: Number(res?.download_count) || 0,
                            }
                        });
                        // console.log(res.daily_count[0].date)
                        // Assuming res?.dailyCounts is of type DailyCount[]
                        const dates: string[] = res?.daily_count.map((item: any) => item.date) || [];
                        const counts: number[] = res?.daily_count.map((item: any) => item.total_count) || [];
                        const processCounts: number[] = res?.daily_count.map((item: any) => item.processed_count) || [];
                        const unprocessCounts: number[] = res?.daily_count.map((item: any) => item.unprocessed_count) || [];


                        setDateList(dates)
                        setCountList(counts)
                        setProccessCountList(processCounts)
                        setUnprocessCountList(unprocessCounts)
                        refreshChart()
                        setProcessing(false)
                    } else {
                        setStatisticsData({
                            data: {
                                processed_count: 0,
                                unprocessed_count: 0,
                                total_count: 0,
                                average_time: '',
                                accepted_count: 0,
                                rejected_count: 0,
                                pending_count: 0,
                                download_count: 0,
                            }
                        });
                        setProcessing(false)
                    }
                })
                .catch((error) => {
                    setProcessing(false)
                    console.error('Error fetching data:', error);
                    // Handle API call error if necessary
                });

            filesByUser({ email: currentUser?.email, username: currentUser?.username, tag: tag })
                .then((res) => {
                    if (res) {
                        console.log(startDate)
                        console.log(new Date(startDate))
                        const filteredData = res?.data?.filter((item: any) => {
                            const uploadDate = new Date(item.upload_date);
                            console.log(uploadDate)
                            return (uploadDate >= new Date(startDate) && uploadDate <= new Date(endDate)) && (item.approval==='rejected');
                        });
                        setResponseTable(filteredData)
                    }

                });
        }else{
            setResponseTable([])
        }
    }, [tag, startDate, endDate]);
    useEffect(() => {
        setCurrentPage(1)
    }, [tag])
    // useEffect(() => {
    //     memberStatistic({ "org_id": currentUser?.organizationId, tag: 'resume' }).then((res: any) => {
    //         setMembersProgressAnalytics(res.data)
    //     })
    // }, [])

    console.log(responseTable)
    console.log(view)
    return (

        <div className="mb-xl-8 px-20" style={{ borderRadius: '0px', minHeight: '90vh' }}>
            <h1 style={{ marginTop: '20px' }}>Analytics Dashboard</h1>
            <div style={{ borderRadius: '0px', minHeight: '90vh' }}>
                <div className='row mt-4'>
                    <div className='col-md-4 d-flex align-items-center'>
                        <select className='form-select' style={{ width: '250px' }} value={tag} onChange={(e) => { setTag(e.target.value) }}>
                            <option selected >Select Document Type</option>
                            {currentUser?.document_service == 1 &&
                                <>
                                    <option value='invoice'>Invoice</option>
                                    <option value='bankstatement'>Bank Statement</option>
                                    <option value='handwritten'>Handwritten</option>
                                    {/* <option value='fax'>Fax</option>
                                    <option value='vouchers'>Vouchers</option>
                                    <option value='receipt'>Reciept</option> */}
                                </>
                            }
                            {currentUser?.resume_service == 1 && <option value='resume'>Resume</option>}
                        </select>
                    </div>
                    <div className='col-md-4 d-flex align-items-center justify-content-end'>
                        <div className='input-group justify-content-end' style={{ marginRight: '10px' }}>
                            <input
                                type='date'
                                min="2023-01-01"
                                max={maxDate}
                                value={startDate.toISOString().split('T')[0]}
                                onChange={(e) => {
                                    selectedDate(new Date(e.target.value), "start"); // Assuming selectedDate is a function
                                }}
                                className='form-control'
                                style={{ width: '80px', padding: '0.375rem 0.75rem' }}
                            />
                            <span className='input-group-text'>to</span>
                            <input
                                type='date'
                                className='form-control'
                                min="2023-01-01"
                                max={maxDate}
                                value={endDate.toISOString().split('T')[0]}
                                onChange={(e) => {
                                    selectedDate(new Date(e.target.value), "end");
                                }}
                                style={{
                                    width: '80px',
                                    padding: '0.375rem 0.75rem',
                                }}
                            />
                        </div>
                    </div>

                    {/* <div className='col-md-4 d-flex align-items-center justify-content-end'>
                        <button className='btn btn-primary'>
                            <i className='bi bi-download'></i> Download Report
                        </button>
                    </div> */}
                </div>
                <div className='row mt-4'>
                    {
                        processing ?
                            <>
                                <div
                                    className={processing ? 'd-flex' : 'd-none'}
                                    style={{
                                        width: '100vw',
                                        height: '100vh',
                                        zIndex: 100,
                                        backgroundColor: '#5e627861',
                                        position: 'fixed',
                                        top: 0,
                                        left: 0,
                                    }}
                                ></div>
                                <Blocks
                                    visible={true}
                                    height="80"
                                    width="80"
                                    ariaLabel="blocks-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="blocks-wrapper"
                                />
                            </>
                            :
                            <>
                                {/* Four additional boxes above the existing ones */}
                                <div className='col-md-3'>
                                    <div className='card' style={{ marginBottom: '20px', height: '100px' }}>
                                        {/* Box 3 content goes here */}
                                        <div className='card-body'>
                                            {/* <h5 className='card-title'><KTIcon iconName="file-up" className="fs-2" /> Processed Count</h5> */}
                                            <div className="d-flex align-items-center mb-6">
                                                {/* begin::Symbol */}
                                                <div className="symbol symbol-45px w-40px me-5">
                                                    <span className="symbol-label bg-lighten">
                                                        <KTIcon iconName="file-up" className="fs-1" />
                                                    </span>
                                                </div>
                                                {/* end::Symbol */}
                                                {/* begin::Description */}
                                                <div className="d-flex align-items-center flex-wrap w-100">
                                                    {/* begin::Title */}
                                                    <div className="mb-1 pe-3 flex-grow-1">
                                                        <a
                                                            href="#"
                                                            className="fs-6 text-gray-800 text-hover-primary fw-bold"
                                                            style={{ margin: '0', opacity: 0.5 }}
                                                        >

                                                            Total Documents <br /> Uploaded
                                                        </a>
                                                    </div>
                                                    {/* end::Title */}
                                                    {/* begin::Label */}
                                                    <div className="d-flex align-items-center">
                                                        <div className="fw-bold fs-1 text-gray-800 pe-1">{statisticsData.data.total_count}</div>
                                                    </div>
                                                    {/* end::Label */}
                                                </div>
                                                {/* end::Description */}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-3'>
                                    <div className='card' style={{ marginBottom: '20px', height: '100px' }}>
                                        {/* Box 4 content goes here */}
                                        <div className='card-body'>
                                            <div className="d-flex align-items-center mb-6">
                                                {/* begin::Symbol */}
                                                <div className="symbol symbol-45px w-40px me-5">
                                                    <span className="symbol-label bg-lighten">
                                                        <KTIcon iconName="filter-tick" className="fs-1" />
                                                    </span>
                                                </div>
                                                {/* end::Symbol */}
                                                {/* begin::Description */}
                                                <div className="d-flex align-items-center flex-wrap w-100">
                                                    {/* begin::Title */}
                                                    <div className="mb-1 pe-3 flex-grow-1">
                                                        <a
                                                            href="#"
                                                            className="fs-6 text-gray-800 text-hover-primary fw-bold"
                                                            style={{ margin: '0', opacity: 0.5 }}
                                                        >

                                                            Processed <br /> Document
                                                        </a>
                                                    </div>
                                                    {/* end::Title */}
                                                    {/* begin::Label */}
                                                    <div className="d-flex align-items-center">
                                                        <div className="fw-bold fs-1 text-gray-800 pe-1">{statisticsData.data.processed_count}</div>
                                                    </div>
                                                    {/* end::Label */}
                                                </div>
                                                {/* end::Description */}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-3'>
                                    <div className='card' style={{ marginBottom: '20px', height: '100px' }}>
                                        <div className='card-body'>
                                            <div className="d-flex align-items-center mb-6">
                                                <div className="symbol symbol-45px w-40px me-5">
                                                    <span className="symbol-label bg-lighten">
                                                        <KTIcon iconName="information" className="fs-1" />
                                                    </span>
                                                </div>
                                                <div className="d-flex align-items-center flex-wrap w-100">
                                                    <div className="mb-1 pe-3 flex-grow-1">
                                                        <a
                                                            href="#"
                                                            className="fs-6 text-gray-800 text-hover-primary fw-bold"
                                                            style={{ margin: '0', opacity: 0.5 }}
                                                        >

                                                            Unprocessed <br /> Document
                                                        </a>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div className="fw-bold fs-1 text-gray-800 pe-1">{statisticsData.data.unprocessed_count}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-3'>
                                    <div className='card' style={{ marginBottom: '20px', height: '100px' }}>
                                        {/* Box 5 content goes here */}
                                        <div className='card-body'>
                                            <div className="d-flex align-items-center mb-6">
                                                {/* begin::Symbol */}
                                                <div className="symbol symbol-45px w-40px me-5">
                                                    <span className="symbol-label bg-lighten">
                                                        <KTIcon iconName="watch" className="fs-1" />
                                                    </span>
                                                </div>
                                                {/* end::Symbol */}
                                                {/* begin::Description */}
                                                <div className="d-flex align-items-center flex-wrap w-100">
                                                    {/* begin::Title */}
                                                    <div className="mb-1 pe-3 flex-grow-1">
                                                        <a
                                                            href="#"
                                                            className="fs-6 text-gray-800 text-hover-primary fw-bold"
                                                            style={{ margin: '0', opacity: 0.5 }}
                                                        >
                                                            Average <br /> Process Time
                                                        </a>
                                                    </div>
                                                    {/* end::Title */}
                                                    {/* begin::Label */}
                                                    <div className="d-flex align-items-center">
                                                        <div className="fw-bold fs-1 text-gray-800 pe-1">{formatAverageTime(statisticsData.data.average_time)} </div>
                                                    </div>
                                                    {/* end::Label */}
                                                </div>
                                                {/* end::Description */}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-3'>
                                    <div className='card' style={{ marginBottom: '20px', height: '100px' }}>
                                        {/* Box 3 content goes here */}
                                        <div className='card-body'>
                                            {/* <h5 className='card-title'><KTIcon iconName="file-up" className="fs-2" /> Processed Count</h5> */}
                                            <div className="d-flex align-items-center mb-6">
                                                {/* begin::Symbol */}
                                                <div className="symbol symbol-45px w-40px me-5">
                                                    <span className="symbol-label bg-lighten">
                                                        <KTIcon iconName="shield-tick" className="fs-1" />
                                                    </span>
                                                </div>
                                                {/* end::Symbol */}
                                                {/* begin::Description */}
                                                <div className="d-flex align-items-center flex-wrap w-100">
                                                    {/* begin::Title */}
                                                    <div className="mb-1 pe-3 flex-grow-1">
                                                        <a
                                                            href="#"
                                                            className="fs-6 text-gray-800 text-hover-primary fw-bold"
                                                            style={{ margin: '0', opacity: 0.5 }}
                                                        >

                                                            Total Documents <br /> Accepted
                                                        </a>
                                                    </div>
                                                    {/* end::Title */}
                                                    {/* begin::Label */}
                                                    <div className="d-flex align-items-center">
                                                        <div className="fw-bold fs-1 text-gray-800 pe-1">{statisticsData.data.accepted_count}</div>
                                                    </div>
                                                    {/* end::Label */}
                                                </div>
                                                {/* end::Description */}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-3'>
                                    <div className='card' style={{ marginBottom: '20px', height: '100px' }}>
                                        {/* Box 3 content goes here */}
                                        <div className='card-body'>
                                            {/* <h5 className='card-title'><KTIcon iconName="file-up" className="fs-2" /> Processed Count</h5> */}
                                            <div className="d-flex align-items-center mb-6">
                                                {/* begin::Symbol */}
                                                <div className="symbol symbol-45px w-40px me-5">
                                                    <span className="symbol-label bg-lighten">
                                                        <KTIcon iconName="shield-cross" className="fs-1" />
                                                    </span>
                                                </div>
                                                {/* end::Symbol */}
                                                {/* begin::Description */}
                                                <div className="d-flex align-items-center flex-wrap w-100">
                                                    {/* begin::Title */}
                                                    <div className="mb-1 pe-3 flex-grow-1">
                                                        <a
                                                            href="#"
                                                            className="fs-6 text-gray-800 text-hover-primary fw-bold"
                                                            style={{ margin: '0', opacity: 0.5 }}
                                                        >

                                                            Total Documents <br /> Rejected
                                                        </a>
                                                    </div>
                                                    {/* end::Title */}
                                                    {/* begin::Label */}
                                                    <div className="d-flex align-items-center">
                                                        <div className="fw-bold fs-1 text-gray-800 pe-1">{statisticsData.data.rejected_count}</div>
                                                    </div>
                                                    {/* end::Label */}
                                                </div>
                                                {/* end::Description */}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-3'>
                                    <div className='card' style={{ marginBottom: '20px', height: '100px' }}>
                                        {/* Box 3 content goes here */}
                                        <div className='card-body'>
                                            {/* <h5 className='card-title'><KTIcon iconName="file-up" className="fs-2" /> Processed Count</h5> */}
                                            <div className="d-flex align-items-center mb-6">
                                                {/* begin::Symbol */}
                                                <div className="symbol symbol-45px w-40px me-5">
                                                    <span className="symbol-label bg-lighten">
                                                        <KTIcon iconName="information" className="fs-1" />
                                                    </span>
                                                </div>
                                                {/* end::Symbol */}
                                                {/* begin::Description */}
                                                <div className="d-flex align-items-center flex-wrap w-100">
                                                    {/* begin::Title */}
                                                    <div className="mb-1 pe-3 flex-grow-1">
                                                        <a
                                                            href="#"
                                                            className="fs-6 text-gray-800 text-hover-primary fw-bold"
                                                            style={{ margin: '0', opacity: 0.5 }}
                                                        >

                                                            Total Documents <br /> Pending Approval
                                                        </a>
                                                    </div>
                                                    {/* end::Title */}
                                                    {/* begin::Label */}
                                                    <div className="d-flex align-items-center">
                                                        <div className="fw-bold fs-1 text-gray-800 pe-1">{statisticsData.data.total_count - (statisticsData.data.accepted_count + statisticsData.data.rejected_count)}</div>
                                                    </div>
                                                    {/* end::Label */}
                                                </div>
                                                {/* end::Description */}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-3'>
                                    <div className='card' style={{ marginBottom: '20px', height: '100px' }}>
                                        {/* Box 3 content goes here */}
                                        <div className='card-body'>
                                            {/* <h5 className='card-title'><KTIcon iconName="file-up" className="fs-2" /> Processed Count</h5> */}
                                            <div className="d-flex align-items-center mb-6">
                                                {/* begin::Symbol */}
                                                <div className="symbol symbol-45px w-40px me-5">
                                                    <span className="symbol-label bg-lighten">
                                                        <KTIcon iconName="file-down" className="fs-1" />
                                                    </span>
                                                </div>
                                                {/* end::Symbol */}
                                                {/* begin::Description */}
                                                <div className="d-flex align-items-center flex-wrap w-100">
                                                    {/* begin::Title */}
                                                    <div className="mb-1 pe-3 flex-grow-1">
                                                        <a
                                                            href="#"
                                                            className="fs-6 text-gray-800 text-hover-primary fw-bold"
                                                            style={{ margin: '0', opacity: 0.5 }}
                                                        >

                                                            Total Documents <br /> Downloaded
                                                        </a>
                                                    </div>
                                                    {/* end::Title */}
                                                    {/* begin::Label */}
                                                    <div className="d-flex align-items-center">
                                                        <div className="fw-bold fs-1 text-gray-800 pe-1">{tag == "resume" ? statisticsData.data.download_count : "N/A"}</div>
                                                    </div>
                                                    {/* end::Label */}
                                                </div>
                                                {/* end::Description */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Team Member Analytics */}
                                {/* <div className={`col-md-12 card ${className}`} style={{ marginBottom: '20px' }}>
                                    <div className='card' style={{ marginBottom: '20px' }}>
                                        <div className='card-body'>
                                            <h5 className='card-title'>Team Member Analytics</h5>
                                            {
                                                (membersProgressAnalytics && membersProgressAnalytics.length > 0) &&
                                                <TeamMemberGraph
                                                    // heading="Text Classifier"
                                                    className="card-xl-stretch mb-xl-8"
                                                    chartColor="success"
                                                    chartHeight="250px"
                                                    graphData={membersProgressAnalytics}
                                                />}
                                        </div>
                                    </div>
                                </div> */}

                                {/* Chart Section */}

                                <div className={`col-md-12 card ${className}`} style={{ marginBottom: '20px', height: '500px' }}>
                                    <div className='card-body'>
                                        {/* Toggle buttons for views */}
                                        <div className='btn-group' style={{ marginBottom: '20px' }}>
                                            <button
                                                className={`btn ${view === 'graph' ? 'btn-primary' : 'btn-outline-primary'}`}
                                                onClick={() => toggleView('graph')}
                                            >
                                                Graph Analytics
                                            </button>
                                            <button
                                                className={`btn ${view === 'table' ? 'btn-primary' : 'btn-outline-primary'}`}
                                                onClick={() => toggleView('table')}
                                            >
                                                Table Analytics
                                            </button>
                                        </div>

                                        {view === 'graph' && (
                                            <div ref={chartRef} id='kt_charts_widget_1_chart' style={{ height: '300px' }} />
                                        )}

                                        {/* begin::Table container */}
                                        {view === 'table' && (<div className='table-responsive'>
                                            {/* begin::Table */}
                                            <table className='table align-middle gs-0 gy-4'>
                                                {/* begin::Table head */}
                                                <thead className='position-sticky top-0'>
                                                    <tr className='fw-bold text-muted bg-light'>

                                                        <th className='min-w-100px'>Filename</th>
                                                        <th className='min-w-100px'>Processed</th>
                                                        <th className='min-w-100px'>Status</th>
                                                        <th className='w-400px'>Feedback</th>


                                                    </tr>
                                                </thead>
                                                {/* end::Table head */}
                                                {/* begin::Table body */}
                                                <tbody>
                                                    {currentResponse.map((item: any) => (
                                                        <tr key={item.id}>
                                                            <td>
                                                                <div className='d-flex justify-content-start flex-column'>
                                                                    <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                                                                        {(item.file_original_name.lastIndexOf('.') > -1 && item.file_original_name.lastIndexOf('.') <= 20) ? item.file_original_name.substring(0, item.file_original_name.lastIndexOf('.')) : item.file_original_name.substring(0, 20)}

                                                                    </a>
                                                                </div>
                                                            </td>
                                                            <td>{
                                                                item.processed == 1 ?
                                                                    <span className='badge badge-light-success'>Proccessed</span>
                                                                    : <span className='badge badge-light-danger'>Unprocessed</span>
                                                            }</td>
                                                            <td> {
                                                                item.approval == "pending" || item.approval == "" || item.approval == null ?
                                                                    <span className='badge badge-light-warning'>Pending</span>
                                                                    : item.approval == "accepted" ?
                                                                        <span className='badge badge-light-success'>Accepted</span>
                                                                        : <span className='badge badge-light-danger'>Rejected</span>
                                                            }</td>
                                                            <td>    <div className='d-flex justify-content-start flex-column'>
                                                                <div className='text-dark  text-hover-primary mb-1 fs-6'>
                                                                    {item.feedback ? item.feedback : "No Feedback"}

                                                                </div>
                                                            </div></td>
                                                        </tr>
                                                    ))}
                                                    {responseTable.length === 0 && (
                                                        <tr>
                                                            <td colSpan={3} className="text-center">No data available for the selected date range.</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                                {/* end::Table body */}
                                            </table>
                                            {/* end::Table */}

                                            {/* end::Table container */}
                                            <div className='card-footer'>
                                                <div className='d-flex flex-stack flex-wrap'>
                                                    <div className='fs-6 fw-bold text-gray-700'>

                                                        {(responseTable.length == 0)
                                                            ? "Showing 0 to 0 of 0 Results"
                                                            : `Showing ${(currentPage - 1) * documentsPerPage + 1} to ${Math.min(
                                                                currentPage * documentsPerPage,
                                                                responseTable.length
                                                            )} of ${responseTable.length
                                                            } Results`}
                                                    </div>
                                                    <ul className='pagination'>
                                                        <li className='page-item previous'>
                                                            <a href='#' className='page-link' onClick={() => handlePageChange(currentPage - 1)}>
                                                                <i className='previous'></i>
                                                            </a>
                                                        </li>
                                                        {[...Array(totalData)].map((_, index) => (
                                                            <li key={index} className={clsx('page-item', { active: index + 1 === currentPage })}>
                                                                <a href='#' className='page-link' onClick={() => handlePageChange(index + 1)}>
                                                                    {index + 1}
                                                                </a>
                                                            </li>
                                                        ))}
                                                        <li className='page-item next'>
                                                            <a href='#' className='page-link' onClick={() => handlePageChange(currentPage + 1)}>
                                                                <i className='next'></i>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        )}
                                    </div>

                                </div>
                            </>
                    }
                </div>
            </div>
        </div>
    );
}
export default Statistics;



function getChartOptions(height: number, countList: number[], dateList: string[], processCountList: number[], unprocessCountList: number[]): ApexOptions {
    const labelColor = getCSSVariableValue('--bs-gray-500');
    const borderColor = getCSSVariableValue('--bs-gray-200');
    const baseColor = getCSSVariableValue('--bs-primary');
    const secondBaseColor = getCSSVariableValue('--bs-success');
    const secondaryColor = getCSSVariableValue('--bs-warning');
    console.log(dateList, countList, processCountList, unprocessCountList)
    return {
        series: [
            {
                name: 'Uploads',
                data: countList,
            },
            {
                name: 'Proccessed',
                data: processCountList,
            },
            {
                name: 'Unprocessed',
                data: unprocessCountList,
            },
        ],
        chart: {
            fontFamily: 'inherit',
            type: 'bar',
            height: height,
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                // Adjust the columnWidth to decrease the width of the bars
                columnWidth: '35%', // Adjust this value as needed
                borderRadius: 5,
            },
        },
        legend: {
            show: true,
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
        },
        xaxis: {
            categories: dateList,
            axisBorder: {
                show: true,
            },
            axisTicks: {
                show: true,
            },
            labels: {
                style: {
                    colors: labelColor,
                    fontSize: '12px',
                },
            },
        },
        yaxis: {
            labels: {
                style: {
                    colors: labelColor,
                    fontSize: '12px',
                },
            },
        },
        fill: {
            opacity: 1,
        },
        states: {
            normal: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            hover: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
        },
        tooltip: {
            style: {
                fontSize: '12px',
            },
            y: {
                formatter: function (val) {
                    return val + ' documents';
                },
            },
        },
        colors: [baseColor, secondBaseColor, secondaryColor],
        grid: {
            borderColor: borderColor,
            strokeDashArray: 4,
            yaxis: {
                lines: {
                    show: true,
                },
            },
        },
    };

}