import { useState } from 'react'
import FormattedResume from './FormattedResume'
import { ResumeTemplateFormattingProps } from '../ResumeFormattingModel'
import ResumeLayoutAndStylingControls from './ResumeLayoutAndStylingControls'

const ResumeTemplateFormatting = ({
    preview,
    template,
    selectedFiles,
    processAction,
    toPDF,
    targetRef,
    resumeJson,
    formatting,
    processedFileLink,
    handleDocumentApproval,
    setTemplate
}: ResumeTemplateFormattingProps) => {
    const [primaryColor, setPrimaryColor] = useState<string>('')

    console.log(selectedFiles)
    return (
        <div>
            <div className='row'>
                <div className='col-4'>
                    <ResumeLayoutAndStylingControls
                        setTemplate={setTemplate}
                        template={template}
                        primaryColor={primaryColor}
                        setPrimaryColor={setPrimaryColor}
                    />
                </div>
                
                
                <div className='col-8'>
                    <FormattedResume
                        preview={preview}
                        selectedFiles={selectedFiles}
                        processAction={processAction}
                        toPDF={toPDF}
                        template={template}
                        primaryColor={primaryColor}
                        targetRef={targetRef}
                        resumeJson={resumeJson}
                        formatting={formatting}
                        processedFileLink={processedFileLink}
                        handleDocumentApproval={handleDocumentApproval} // Pass handleDocumentApproval prop
                    />
                </div>

            </div>
        </div>
    )
}

export default ResumeTemplateFormatting