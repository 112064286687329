import clsx from 'clsx';
import React, { useEffect, useState, useRef } from 'react';
import { KTIcon, toAbsoluteUrl } from '../../../../_metronic/helpers';
import { documentApproval, documentAssignTo, documentFeedback, getAllMember, processDocuments } from '../../../api';
import { UsersListFilter } from '../../apps/user-management/users-list/components/header/UsersListFilter';
import { useAuth } from '../../auth';
import { DocumentLibraryViewProps, FileDetails, TeamMemberObject } from '../DocumentProcessingModel';
import { DocumentTable } from './DocumentTable';

export const DocumentLibraryView: React.FC<DocumentLibraryViewProps> = ({ doctype, documents, setRefresh, preview, toggleState, setFileName, onReview, handleDownload, handleDelete, setSelectedFiles, setTabButton, processing, setTab, tabProp, currentPageReturn, setCurrentPageReturn, indexing,setProcessDate}) => {
    const [tab, setTabLibrary] = useState("processed");
    const [processedDoc, setProcessedDoc] = useState<FileDetails[]>([]);
    const [unprocessedDoc, setUnprocessedDoc] = useState<FileDetails[]>([]);
    const [teams, setTeams] = useState<string[]>()
    const [selectedFiles, setSelectedFilesDoc] = useState<string[]>([]);
    const [feedback, setFeedback] = useState<string>()
    const [teamMember, setTeamMember] = useState<string>()
    const [processAction, setProcessAction] = useState<string | null>(null)
    const { currentUser } = useAuth();
    const [processedDocSearch, setProcessedDocSearch] = useState<FileDetails[]>([])
    const [unprocessedDocSearch, setUnprocessedDocSearch] = useState<FileDetails[]>([])

    const [searchQuery, setSearchQuery] = useState<string>("");
    // Pagination state
    const [currentPage, setCurrentPage] = useState(currentPageReturn);
    const [documentsPerPage, setDocumentsPerPage] = useState<number>(10);


    // Function to update selected files
    const updateSelectedFiles = (files: string[]) => {
        setSelectedFilesDoc(files)
        setSelectedFiles(() => [...files]); // Update the selected files in the parent component
        console.log("resume library", selectedFiles)
    };

    useEffect(() => {
        if (processing) {
            setTab("unprocessed");
        }
    }, [processing])

    useEffect(() => {
        console.log("documentLibrary", tabProp)
        setTabLibrary(tabProp)
    }, [tabProp])


    useEffect(() => {
        console.log("insideuseEffec", currentPage)
        setCurrentPageReturn(currentPage)
    }, [currentPage])
    console.log(processing)
    console.log("documentLibrarytapprop", tabProp)
    console.log("documentLibrary", tab)
    const usePrevious = (value: any) => {
        const ref = useRef<any>();
        useEffect(() => {
            ref.current = value;
        }, [value]);
        return ref.current;
    };

    const filterByName = (e: any) => {
        setSearchQuery(e.target.value)
    }


    useEffect(() => {
        setSelectedFiles(selectedFiles)
    }, [selectedFiles])
    setTabButton(tab)

    const handlePerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setDocumentsPerPage(Number(event.target.value));
        setCurrentPage(1); // Reset to first page when per-page count changes
    };


    const handleDocumentApproval = (approval: string) => {
        if (selectedFiles.length > 0) {
            setProcessAction(approval)
            let files: Array<Object> = [];
            selectedFiles.forEach((selectedFile) => {
                let fileObj = {
                    file_url: selectedFile
                };
                files.push(fileObj);
            });
            documentApproval({
                useremail: currentUser?.email,
                files: files,
                approval: approval
            }).then((res) => {
                setProcessAction(null)
                alert(res.message)
                setRefresh(true)
            })
        } else {
            alert("No file selected")
        }
    }

    const handleAssingMember = (e: any) => {
        e.preventDefault()
        if (selectedFiles.length > 0) {
            setProcessAction("assinging")
            let files: Array<Object> = [];
            selectedFiles.forEach((selectedFile) => {
                let fileObj = {
                    file_url: selectedFile
                };
                files.push(fileObj);
            });
            documentAssignTo({
                useremail: currentUser?.email,
                files: files,
                assignTo: teamMember
            }).then((res) => {
                setProcessAction(null)
                alert(res.message)
                setRefresh(true)
            })
        } else {
            alert("No file selected")
        }
    }

    const handleSubmitFeedback = (e: any) => {
        // e.preventDefault()
        if (selectedFiles.length > 0) {
            setProcessAction("feedback")
            let files: Array<Object> = [];
            selectedFiles.forEach((selectedFile) => {
                let fileObj = {
                    file_url: selectedFile
                };
                files.push(fileObj);
            });
            documentFeedback({
                useremail: currentUser?.email,
                files: files,
                feedback: feedback
            }).then((res) => {
                setProcessAction(null)
                alert(res.message)
                setRefresh(true)
            })
        } else {
            alert("No file selected")
        }
    }



    useEffect(() => {
        console.log("usecurrentpage", currentPageReturn)
        setCurrentPage(currentPageReturn)
    }, [processing])




    // console.log("processing",processing)

    // Calculate the total number of pages
    const totalProcessedPages = Math.ceil(processedDocSearch.length / documentsPerPage);
    const totalUnprocessedPages = Math.ceil(unprocessedDocSearch.length / documentsPerPage);

    // Slice the documents to be displayed on the current page
    const currentProcessedDocs = processedDocSearch.slice((currentPage - 1) * documentsPerPage, currentPage * documentsPerPage);
    const currentUnprocessedDocs = unprocessedDocSearch.slice((currentPage - 1) * documentsPerPage, currentPage * documentsPerPage);
    console.log("currentPage", currentPage)


    // Handle page change
    const handlePageChange = (page: number) => {
        if (page > 0 && page <= (tab === "processed" ? totalProcessedPages : totalUnprocessedPages)) {
            setCurrentPage(page);
        }
    };

    useEffect(() => {
        setCurrentPage(currentPageReturn)
        setSelectedFiles(() => [])
    }, [tab])



    useEffect(() => {
        const proccessedDocument = documents.filter((files: FileDetails) => files.processed == 1)
        const unproccessedDocument = documents.filter((files: FileDetails) => files.processed == 0)
        setProcessedDoc(proccessedDocument);
        setProcessedDocSearch(proccessedDocument);
        setUnprocessedDoc(unproccessedDocument);
        setUnprocessedDocSearch(unproccessedDocument);
        console.log("filtered docs", proccessedDocument, unproccessedDocument)




        if (tab == 'processed') {
            const filterBySearch = proccessedDocument.filter((item) => {
                if (item.file_original_name && item.file_original_name.toLowerCase()
                    .includes(searchQuery.toLowerCase())) { return item; }
            })
            setProcessedDocSearch(filterBySearch);
        } else {

            const filterBySearch = unproccessedDocument.filter((item) => {
                if (item.file_original_name && item.file_original_name.toLowerCase()
                    .includes(searchQuery.toLowerCase())) { return item; }
            })
            setUnprocessedDocSearch(filterBySearch);
        }

    }, [documents, searchQuery, tab])

    // useEffect(() => {
    //     getAllMember({ organizationId: currentUser?.organizationId }).then((res) => {
    //         //  Filtering out emails of objects with type !== 'superadmin'
    //         const emails = res.data
    //             .filter((user: TeamMemberObject) => user.type !== 'superadmin')
    //             .map((user: TeamMemberObject) => user.email);

    //         setTeams(emails)
    //     });
    //     console.log(documents)
    // }, [])

    useEffect(() => {
        console.log(selectedFiles)
    }, [selectedFiles])

    return (
        <div>
            <div className="row d-flex justify-content-between position-relative">
                {/* Assign Team */}
                <div
                    className="modal fade"
                    id="kt_modal_assign_review"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered mw-650px">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h2 className="fw-bolder">Assign Document to Team Member</h2>
                                <div
                                    className="btn btn-sm btn-icon btn-active-color-primary"
                                    data-bs-dismiss="modal"
                                >
                                    <KTIcon iconName="cross" className="fs-1" />
                                </div>
                            </div>
                            <div className="modal-body scroll-y p-2">
                                <div
                                    className={`card card-xl-stretch mb-2 px-2`}
                                    style={{ maxHeight: "60vh", overflowY: "scroll" }}
                                >
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="row mb-6">
                                                <label className="col-lg-4 col-form-label fw-bold fs-4 mt-5">
                                                    <span className="">Team Member</span>
                                                </label>

                                                <div className="col-lg-8 fv-row">
                                                    <small className="px-2 fs-8">Select team member who will process or validate the document</small>

                                                    {
                                                        teams &&
                                                        <select
                                                            className="form-select form-select-solid"
                                                            data-kt-select2="true"
                                                            data-placeholder="Select option"
                                                            data-allow-clear="true"
                                                            value={teamMember}
                                                            onChange={(e) => { setTeamMember(e.target.value) }}
                                                        >
                                                            <option value="">---Add Team---</option>
                                                            {teams.map((team, index) => (
                                                                <option key={index} value={team}>{team}</option>
                                                            ))}
                                                        </select>
                                                    }
                                                </div>
                                            </div>
                                            {/* <div className="row mb-6">
                                                <label className="col-lg-4 col-form-label fw-bold fs-6">
                                                    <span className="">Notify Users After</span>
                                                </label>

                                                <div className="col-lg-8 fv-row">
                                                    <input
                                                        type="text"
                                                        // className="form-select form-select-solid"
                                                        className='form-control'
                                                        placeholder='Time'
                                                    />
                                                    <small className="px-2 fs-4">Send notification email after specified reviewing hours</small>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>

                                </div>
                                <div className="my-2 px-2 text-center d-flex justify-content-end">
                                    <button
                                        className="btn btn-lg btn-success"
                                        data-bs-dismiss="modal"
                                        onClick={(e) => handleAssingMember(e)}
                                    >
                                        Assign
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Provide Feedback */}
                <div
                    className="modal fade"
                    id="kt_modal_feedback_review"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered mw-650px">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h2 className="fw-bolder">Provide Give Feedback about the Processed Document</h2>
                                <div
                                    className="btn btn-sm btn-icon btn-active-color-primary"
                                    data-bs-dismiss="modal"
                                >
                                    <KTIcon iconName="cross" className="fs-1" />
                                </div>
                            </div>
                            <div className="modal-body scroll-y p-2">
                                <div
                                    className={`card card-xl-stretch mb-2 px-2`}
                                    style={{ maxHeight: "60vh", overflowY: "scroll" }}
                                >
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="row mb-6">
                                                {/* <label className="col-12 col-form-label fw-bold fs-2">
                                                    <span className="px-1">Feedback</span>
                                                </label> */}

                                                <div className="col-12 fv-row">
                                                    {/* <small className="px-1 fs-8 mb-1">Based on the extracted data provide feedback</small> */}
                                                    <textarea
                                                        value={feedback}
                                                        onChange={(e) => { setFeedback(e.target.value) }}
                                                        className='form-control form-control-solid mb-2'
                                                        rows={3}
                                                        placeholder='Type your comments here' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="my-2 px-2 text-center d-flex justify-content-end">
                                    <button
                                        className="btn btn-lg btn-success"
                                        data-bs-dismiss="modal"
                                        onClick={(e) => handleSubmitFeedback(e)}
                                    >
                                        Give Feedback
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-sm-12 col-md-12">
                    <div
                        className={`card card-xl-stretch mb-5 mb-xl-12`}
                    >
                        <div className="card-header card-header-stretch position-sticky top-0 z-index-1">
                            <ul
                                className="nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap"
                                role="tablist"
                            >
                                {/* assosiated tab btn */}
                                <li className="nav-item">
                                    <a
                                        className={clsx(`nav-link cursor-pointer  text-white-50`, {
                                            active: tab === "processed"
                                        })}
                                        onClick={() => setTabLibrary("processed")}
                                        role="tab"
                                        style={
                                            tab == "processed"
                                                ? { borderBottom: "4px solid #fff" }
                                                : { borderBottom: "" }
                                        }
                                    >
                                        Processed Documents
                                    </a>
                                </li>

                                {/* unassosiated tab btn */}
                                <li className="nav-item">
                                    <a
                                        className={clsx(`nav-link cursor-pointer  text-white-50`, {
                                            active: tab === "unassosiated"
                                        })}
                                        onClick={() => setTabLibrary("unprocessed")}
                                        role="tab"
                                        style={
                                            tab == "unprocessed"
                                                ? { borderBottom: "4px solid #fff" }
                                                : { borderBottom: "" }
                                        }
                                    >
                                        Unprocessed Documents
                                    </a>
                                </li>
                            </ul>
                            <div className='row my-3'>
                                <div className='col-auto'>
                                    <div className="input-group">
                                        <span className="input-group-text" id="basic-addon1">
                                            <KTIcon className='fs-2' iconName={'magnifier'} />
                                        </span>
                                        <input
                                            type="text"
                                            className="form-control w-120px"
                                            placeholder="Search Document"
                                            aria-label="Search"
                                            aria-describedby="basic-addon1"
                                            onChange={(e) => filterByName(e)}

                                        />
                                        {/* <UsersListFilter />  */}
                                    </div>
                                </div>
                                {/* <div className='col-auto'>
                                    {
                                        (currentUser?.usertype == 'superadmin' && selectedFiles.length > 0) &&
                                        <button
                                            type="button"
                                            disabled={selectedFiles.length == 0}
                                            className="btn btn-sm btn-primary me-3 w-180px fs-4"
                                            data-bs-toggle="modal"
                                            data-bs-target="#kt_modal_assign_review"
                                        >
                                            {
                                                processAction == "assinging" ?
                                                    <span className="indicator-progress" style={{ display: "block" }}>
                                                        Processing...{" "}
                                                        <span className="spinner-border align-middle fs-6"></span>
                                                    </span>
                                                    : <span className='d-flex align-items-center justify-content-between'>
                                                        <img src={toAbsoluteUrl("/media/custom-icon/assign-file.png")} alt="Thirdeye data" height={22} /> &nbsp;
                                                        Assign
                                                    </span>
                                            }
                                        </button>
                                    }
                                </div> */}
                            </div>
                        </div>
                        <form className="form">
                            <div className="card-body">
                                <div className="tab-content">
                                    {/* assosiated tab view */}
                                    <div
                                        className={clsx("tab-pane", { active: tab === "processed" })}
                                    >
                                        {currentProcessedDocs.length > 0 ? (
                                            <DocumentTable
                                                className=''
                                                documentData={currentProcessedDocs}
                                                type={doctype}
                                                setRefresh={setRefresh}
                                                setProcessDate={setProcessDate}
                                                preview={preview}
                                                toggleState={toggleState}
                                                setFileName={setFileName}
                                                onReview={onReview}
                                                setSelectedFiles={updateSelectedFiles}
                                                handleDownload={handleDownload}
                                                handleDelete={handleDelete}
                                                processing={processing}
                                                indexing={indexing}
                                                tab={tab}
                                            />
                                        ) : (
                                            <div className="card-body pt-5">
                                                <p className="fw-bold fs-2 text-center">
                                                    No Files Present.
                                                </p>
                                            </div>
                                        )
                                        }
                                    </div>
                                </div>
                                <div className="tab-content pt-3">
                                    {/* assosiated tab view */}
                                    <div
                                        className={clsx("tab-pane", { active: tab === "unprocessed" })}
                                    >
                                        {currentUnprocessedDocs.length > 0 ? (
                                            <DocumentTable
                                                className=''
                                                documentData={currentUnprocessedDocs}
                                                type={doctype}
                                                setRefresh={setRefresh}
                                                setProcessDate={setProcessDate}
                                                preview={preview}
                                                toggleState={toggleState}
                                                setFileName={setFileName}
                                                onReview={onReview}
                                                setSelectedFiles={updateSelectedFiles}
                                                handleDownload={handleDownload}
                                                handleDelete={handleDelete}
                                                processing={processing}
                                                indexing={indexing}
                                                tab={tab}
                                            />
                                        ) : (
                                            <div className="card-body pt-5">
                                                <p className="fw-bold fs-2 text-center">
                                                    No Files Present.
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className='card-footer'>
                            <div className='d-flex flex-stack flex-wrap'>
                                <div className='fs-6 fw-bold text-gray-700'>

                                    {((tab === "processed" && processedDocSearch.length === 0) ||
                                        (tab === "unprocessed" && unprocessedDocSearch.length === 0))
                                        ? "Showing 0 to 0 of 0 Results"
                                        : `Showing ${(currentPage - 1) * documentsPerPage + 1} to ${Math.min(
                                            currentPage * documentsPerPage,
                                            tab === "processed" ? processedDocSearch.length : unprocessedDocSearch.length
                                        )} of ${tab === "processed" ? processedDocSearch.length : unprocessedDocSearch.length
                                        } Results`}


                                </div>
                                {/* page per row */}
                                <div className="ms-1 d-flex align-items-center flex-wrap gap-2  border rounded bg-light">
                                    <label
                                        htmlFor="perPageSelect"
                                        className="mb-0 fs-6 fw-bold text-gray-700"
                                        style={{ marginRight: '8px' }}
                                    >
                                        Items per page:
                                    </label>
                                    <select
                                        id="perPageSelect"
                                        className="form-select form-select-sm w-auto"
                                        value={documentsPerPage}
                                        onChange={handlePerPageChange}
                                        style={{ minWidth: '60px' }}
                                    >
                                        <option value={10}>10</option>
                                        <option value={15}>15</option>
                                        <option value={20}>20</option>
                                    </select>
                                </div>


                                <ul className='pagination'>

                                    <li className='page-item first'>
                                        <button
                                            className='page-link btn btn-sm btn-success'
                                            onClick={() => handlePageChange(1)}
                                            disabled={currentPage === 1}
                                        >
                                            First
                                        </button>
                                    </li>

                                    <li className='page-item previous'>
                                        <a href='#' className='page-link' onClick={() => handlePageChange(currentPage - 1)}>
                                            <i className='previous'></i>
                                        </a>
                                    </li>
                                    {[...Array(tab === "processed" ? totalProcessedPages : totalUnprocessedPages)].map((_, index) => (
                                        <li key={index} className={clsx('page-item', { active: index + 1 === currentPage })}>
                                            <a href='#' className='page-link' onClick={() => handlePageChange(index + 1)}>
                                                {index + 1}
                                            </a>
                                        </li>
                                    ))}
                                    <li className='page-item next'>
                                        <a href='#' className='page-link' onClick={() => handlePageChange(currentPage + 1)}>
                                            <i className='next'></i>
                                        </a>
                                    </li>
                                    <li className='page-item last'>
                                        <button
                                            className='page-link btn btn-sm btn-success'
                                            onClick={() => handlePageChange(tab === "unprocessed" ? totalUnprocessedPages : totalProcessedPages)}
                                            disabled={currentPage === (tab === "unprocessed" ? totalUnprocessedPages : totalProcessedPages)}
                                        >
                                            Last
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
