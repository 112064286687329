import { useNavigate } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';

export function AccountAlreadyVerified() {
    const navigate = useNavigate();

    const handleLoginRedirect = () => {
        navigate('/auth');
    };

    return (
        <div
            className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
            style={{
                backgroundImage: `url(${toAbsoluteUrl(
                    "/media/illustrations/sketchy-1/14.png"
                )})`,
            }}
        >
            <div className="d-flex flex-column flex-column-fluid p-10 pb-lg-20">
                <a href="https://optira.ai/" target="_blank" rel="noreferrer">
                    <div className="d-flex align-items-start mb-2">
                        <img
                            alt="Logo"
                            src={toAbsoluteUrl("/media/logos/default.png")}
                            className="h-65px"
                        />
                        <div className="d-flex flex-column ml-3">
                            <span className="fw-bolder fs-1 text-primary">Optira</span>
                            <span className="fs-6 text-white">AI-based Document Processing & Analytics Platform</span>
                        </div>
                    </div>
                </a>

                <div
                    className="w-lg-500px bg-body rounded p-10 p-lg-15 mx-auto"
                    style={{ boxShadow: '6px 6px 19px #0c101b, -6px -6px 19px #1c263f' }}
                >
                    <div className="text-center mb-10">
                        <h1 className="text-dark fw-bolder mb-3">Account Already Verified</h1>
                        <p className="text-white-500 fw-semibold fs-6">
                            Your account has already been verified. Click the button below to proceed to the login page.
                        </p>
                    </div>

                    <button
                        onClick={handleLoginRedirect}
                        className="btn btn-primary w-100"
                    >
                        Go to Login
                    </button>
                </div>
            </div>

            <div className="p-10">
                <div className="row">
                    <div className="col-4">
                        <div className="">
                            <div className="d-flex fw-bold fs-4 align-items-start my-2">
                                <span>A Real-world AI Product by
                                    <a
                                        href="https://thirdeyedata.ai/"
                                        target="_blank"
                                        className="text-hover-primary px-2"
                                        style={{ color: "#fff", textTransform: "uppercase", fontWeight: "bold" }}
                                        rel="noreferrer"
                                    >
                                        <img src={toAbsoluteUrl("/media/logos/thirdeyelogo-horizontal.png")} alt="Thirdeye data" height={25} className="mb-3" /> &nbsp;
                                    </a>
                                </span>
                            </div>
                            <span className="fw-light fs-7">All Rights Reserved © 2024 - ThirdEye Data (USA)</span>
                        </div>
                    </div>
                    <div className="col-4 d-flex align-items-center justify-content-center">
                        <div className="d-flex align-items-center fw-bold fs-2">
                            <a
                                href="https://optira.ai/"
                                target="_blank"
                                className="text-hover-primary px-2"
                                style={{ color: "#fff" }}
                                rel="noreferrer"
                            >
                                Sales
                            </a>

                            <a
                                href="https://optira.ai/submit-ticket/"
                                target="_blank"
                                className="text-hover-primary px-2"
                                style={{ color: "#fff" }}
                                rel="noreferrer"
                            >
                                Support
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
