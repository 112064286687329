import React, { useEffect, useState } from "react";
import { Blocks } from "react-loader-spinner";
import { useParams } from "react-router-dom";
import { utils, writeFile } from "xlsx";
import { KTIcon } from "../../../_metronic/helpers";
import { documentApproval, documentAssignTo, documentFeedback, getAllMember, getAudioFiles, processAudioFiles } from "../../api";
import { AddDatasetForm } from "../../modules/audioprocessing/AddDatasetForm";
import { ButtonRow } from "../../modules/audioprocessing/ButtonRow";
import { DocumentLibraryView } from "../../modules/audioprocessing/DocumentLibraryView";
import DocumentReviewView from "../../modules/audioprocessing/DocumentReviewView";
import { HeaderSection } from "../../modules/audioprocessing/HeaderSection";
import { useAuth } from "../../modules/auth";
// import { saveAs } from 'file-saver';

type Props = {};

type fileType = Array<string>;

interface TeamMemberObject {
  username: string;
  email: string;
  password: string;
  organizationId: number;
  type: string;
}

type MyObject = {
  [key: string]: string[];
};

interface Folder {
  files: Array<{
    original_file_name: string,
    uploaded_file_url: string,
    tag: string,
    blobLocation: string,
    processed: number,
    containerName: string,
    userEmail: string,
    upload_date: string,
    assign_to: string,
    feedback: string,
    approval: string
  }>
}

type FolderObject = {

}

export default function AudioProcessing({ }: Props) {
  const { currentUser } = useAuth();
  const { doctype } = useParams()

  const [folder, setFolder] = useState<Folder>();
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
  const [result, setResult] = useState<MyObject>({});
  const [resultNew, setResultNew] = useState<MyObject>({});
  const [resObj, setResObj] = useState<MyObject>({});
  const [viewtables, setViewTables] = useState<Array<MyObject>>([]);
  const [tables, setTables] = useState<Array<Array<MyObject>>>([]);
  const [tableHeader, setTableHeader] = useState<string[][]>([]);
  const [processing, setProcessing] = useState<boolean>(false);
  const [preview, setPreview] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>("");
  const [downlodingJson, setDownloadingJson] = useState<boolean>(false);
  const [responseJson, setResponseJson] = useState<Array<Array<Object>>>([])
  const [resultView, setResultView] = useState<string>("default")
  const [sasUrl, setSasUrl] = React.useState("");
  const [closeModal, setCloseModal] = useState(false);
  const [downloadFormat, setDownloadFormat] = useState('csv')
  const [respError, setRespError] = useState(false)
  const [refresh, setRefresh] = useState<boolean>(false)
  const [fileUrl, setFileUrl] = useState<string>()
  const [downloadType, setDownloadType] = useState<string>()
  const [teams, setTeams] = useState<string[]>()
  const [feedback, setFeedback] = useState<string>()
  const [teamMember, setTeamMember] = useState<string>()
  const [processAction, setProcessAction] = useState<string | null>(null)
  const [audioFile, setAudioFile] = useState<string>("")
  const [transcription, setTranscription] = useState<string>("")

  const handleProcess = (fileLink?: string): Promise<void> => {
    // Set initial states
    setProcessing(true); // Set processing state to true
    setRespError(false); // Reset response error state
    setViewTables([]); // Clear view tables
    setResponseJson([]); // Clear response JSON
    setSelectedFiles([]); // Clear selected files
    setResultNew({}); // Clear result new

    let fileList: Array<Object> = []; // Initialize an array to hold file objects

    // If a fileLink is provided, add it to the selectedFiles array
    if (fileLink) {
      let tempSelectedFiles = selectedFiles;
      tempSelectedFiles.push(fileLink);
      setSelectedFiles(tempSelectedFiles);

      // Create file object and push it to fileList
      let fileObj = {
        fileLink: fileLink
      };
      fileList.push(fileObj);
    } else {
      // If no fileLink is provided, iterate through selectedFiles
      selectedFiles.forEach((selectedFile) => {
        // Create file object for each selected file and push it to fileList
        let fileObj = {
          fileLink: selectedFile
        };
        fileList.push(fileObj);
      });
    }

    // Make API call to process documents
    return new Promise<void>((resolve, reject) => {
      processAudioFiles({
        files: fileList,
        userName: currentUser?.username,
        email: currentUser?.email
      }).then((res) => {
        setTranscription(res.transcription)
        // Process response data
        let tempTableHeader: string[][] = []; // Temporary array to hold table headers
        let tempJsonResp: Object[][] = []; // Temporary array to hold JSON response
        let responseNew = res.data; // Get response data
        setResultNew(responseNew); // Set result new

        // Iterate through response data
        Object.entries(responseNew).map(([key, value], index) => {
          console.log("type: ", typeof (value));
          let resJson: Array<Object> = [];

          // Check if value is an array and not empty
          if (Array.isArray(value) && value.length > 0 && typeof value[0] === "object") {
            let viewKeyList = Object.keys(value[0] || {}); // Get keys of first object in the array
            console.log(viewKeyList);
            tempTableHeader.push(viewKeyList); // Push keys to temporary table header array
            setTableHeader([...tempTableHeader]); // Set table header

            // Iterate through array elements
            value.map((val) => {
              var abcObj: { [key: string]: string } = {}; // Create object to hold key-value pairs
              Object.entries(val).map(([itemKey, itemValue], index) => {
                abcObj[itemKey] = String(itemValue); // Convert each value to string and assign to object
              });
              resJson.push(abcObj); // Push object to response JSON array
            });
          }
          // Check if response JSON array is not empty and push it to temporary response array
          resJson.length > 0 && tempJsonResp.push(resJson);
        });

        sessionStorage.setItem("response", JSON.stringify(res.data)); // Store response data in session storage
        setResponseJson(tempJsonResp); // Set response JSON
        console.log(tempJsonResp);

        // Make API call to get files by user
        getAudioFiles({ email: currentUser?.email, tag: doctype, usertype: currentUser?.usertype }).then((res) => {
          setFolder(res.data); // Set folder state with response data
        });

        setProcessing(false); // Set processing state to false
        resolve(); // Resolve the promise
      }).catch((error) => {
        setRespError(true); // Set response error state to true
        setProcessing(false); // Set processing state to false
        reject(error); // Reject the promise with error
      });
    });
  };

  // useEffect(() => {
  //   getAllMember({ organizationId: currentUser?.organizationId }).then((res) => {
  //     //  Filtering out emails of objects with type !== 'superadmin'
  //     const emails = res.data
  //       .filter((user: TeamMemberObject) => user.type !== 'superadmin')
  //       .map((user: TeamMemberObject) => user.email);

  //     setTeams(emails)
  //   });
  // }, [])

  const toggleState = () => {
    setPreview(prevState => !prevState); // Invert the current state value
  };

  useEffect(() => {
    getAudioFiles({ email: currentUser?.email }).then((res) => {
      console.log(res)
      setFolder(res.data);
    });
  }, []);

  useEffect(() => {
    if (refresh) {
      let tempFolder = { files: [] }
      setFolder(tempFolder)
      getAudioFiles({ email: currentUser?.email, tag: doctype, usertype: currentUser?.usertype }).then((res) => {
        setFolder(res.data);
        setRefresh(false)
      });
    }
  }, [refresh])

  const handleDocumentApproval = () => {

  }

  const handleDownload = () => {

  }

  const handleAssingMember = (e: any) => {

  }

  const handleSubmitFeedback = (e: any) => {

  }

  return (
    <div>

      {/* Upload Document */}
      <div
        className="modal fade"
        id="kt_modal_assosiate_student"
        aria-hidden="true"
      >
        {/* begin::Modal dialog */}
        <div className="modal-dialog modal-dialog-centered mw-650px">
          {/* begin::Modal content */}
          <div className="modal-content">
            <div className="modal-header">
              {/* begin::Modal title */}
              <h2 className="fw-bolder">Upload Document</h2>
              {/* end::Modal title */}

              {/* begin::Close */}
              <button
                className="btn btn-sm btn-icon btn-active-color-primary"
                data-bs-dismiss="modal"
                onClick={(e) => setCloseModal(true)}
              >
                <KTIcon iconName="cross" className="fs-1" />
              </button>
              {/* end::Close */}
            </div>
            {/* begin::Modal body */}
            <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
              {currentUser?.usertype == 'superadmin' ? <AddDatasetForm
                closeModal={closeModal}
              // doctype={doctype}
              /> : <p className="fs-1 fw-bold text-center">Feature Disabled</p>
              }
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>

      {/* Preview Document */}
      <div className="modal fade" id="kt_modal_preview" aria-hidden="true">
        {/* begin::Modal dialog */}
        <div className="modal-dialog modal-dialog-centered mw-650px">
          {/* begin::Modal content */}
          <div className="modal-content">
            <div className="modal-header">
              {/* begin::Modal title */}
              <h2 className="fw-bolder">Preview Document</h2>
              {/* end::Modal title */}

              {/* begin::Close */}
              <div
                className="btn btn-sm btn-icon btn-active-color-primary"
                data-bs-dismiss="modal"
              >
                <KTIcon iconName="cross" className="fs-1" />
              </div>
              {/* end::Close */}
            </div>
            {/* begin::Modal body */}
            <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
              <div>
                <iframe
                  src={sasUrl}
                  title="PDF Preview"
                  width="100%"
                  height="500px"
                ></iframe>
              </div>
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>

      {/* Assign Team */}
      <div
        className="modal fade"
        id="kt_modal_assign"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered mw-650px">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="fw-bolder">Assign Document to Team Member</h2>
              <div
                className="btn btn-sm btn-icon btn-active-color-primary"
                data-bs-dismiss="modal"
              >
                <KTIcon iconName="cross" className="fs-1" />
              </div>
            </div>
            <div className="modal-body scroll-y p-2">
              <div
                className={`card card-xl-stretch mb-2 px-2`}
                style={{ maxHeight: "60vh", overflowY: "scroll" }}
              >
                <div className="row">
                  <div className="col-12">
                    <div className="row mb-6">
                      <label className="col-lg-4 col-form-label fw-bold fs-4 mt-5">
                        <span className="">Team Member</span>
                      </label>

                      <div className="col-lg-8 fv-row">
                        <small className="px-2 fs-8">Select team member who will process or validate the document</small>

                        {
                          teams &&
                          <select
                            className="form-select form-select-solid"
                            data-kt-select2="true"
                            data-placeholder="Select option"
                            data-allow-clear="true"
                            value={teamMember}
                            onChange={(e) => { setTeamMember(e.target.value) }}
                          >
                            <option value="">---Assign Team Member---</option>
                            {teams.map((team) => (
                              <option value={team}>{team}</option>
                            ))}
                          </select>
                        }
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div className="my-2 px-2 text-center d-flex justify-content-end">
                <button
                  className="btn btn-lg btn-success"
                  data-bs-dismiss="modal"
                  onClick={(e) => handleAssingMember(e)}
                >
                  Assign
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Provide Feedback */}
      <div
        className="modal fade"
        id="kt_modal_feedback"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered mw-650px">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="fw-bolder">Provide Give Feedback about the Processed Document</h2>
              <div
                className="btn btn-sm btn-icon btn-active-color-primary"
                data-bs-dismiss="modal"
              >
                <KTIcon iconName="cross" className="fs-1" />
              </div>
            </div>
            <div className="modal-body scroll-y p-2">
              <div
                className={`card card-xl-stretch mb-2 px-2`}
                style={{ maxHeight: "60vh", overflowY: "scroll" }}
              >
                <div className="row">
                  <div className="col-12">
                    <div className="row mb-6">
                      {/* <label className="col-12 col-form-label fw-bold fs-2">
                                                    <span className="px-1">Feedback</span>
                                                </label> */}

                      <div className="col-12 fv-row">
                        {/* <small className="px-1 fs-8 mb-1">Based on the extracted data provide feedback</small> */}
                        <textarea
                          value={feedback}
                          onChange={(e) => { setFeedback(e.target.value) }}
                          className='form-control form-control-solid mb-2'
                          rows={3}
                          placeholder='Type your comments here' />
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div className="my-2 px-2 text-center d-flex justify-content-end">
                <button
                  className="btn btn-lg btn-success"
                  data-bs-dismiss="modal"
                  onClick={(e) => handleSubmitFeedback(e)}
                >
                  Give Feedback
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card card-xl-stretch mb-xl-8 bg-light" style={{ borderRadius: '0px', minHeight: '90vh' }}>
        {/* begin::Header */}
        <HeaderSection doctype={doctype ? doctype : ""} />
        {/* end::Header */}

        <ButtonRow
          tables={tables}
          handleProcess={handleProcess}
          resultNew={resultNew}
          responseJson={responseJson}
          setResultView={setResultView}
          resultView={resultView}
          toggleState={toggleState}
          preview={preview}
        />

        {folder && folder.files.length > 0 ? (
          <div className="card-body pt-5">
            {
              preview ?
                <DocumentReviewView
                  transcribe={transcription}
                  audioFile={audioFile}
                  fileName={fileName}
                  doctype={doctype ? doctype : ""}
                  resultNew={resultNew}
                  resultView={resultView}
                  tableHeader={tableHeader}
                  responseJson={responseJson}
                  processAction={processAction}
                  selectedFiles={selectedFiles}
                  processing={processing}
                  handleDocumentApproval={handleDocumentApproval}
                /> :
                <>
                  {
                    folder &&
                    <DocumentLibraryView
                      doctype={doctype ? doctype : "Document"}
                      documents={folder.files}
                      setRefresh={setRefresh}
                      preview={preview}
                      toggleState={toggleState}
                      setAudioFile={setAudioFile}
                      setTranscription={setTranscription}
                      setFileName={setFileName}
                      onReview={handleProcess}
                      handleDownload={handleDownload}
                    />
                  }
                </>
            }
          </div>
        ) : (
          <div className="row">
            {folder?.files.length == 0 ?
              <div className="card-body pt-5">
                <p className="fw-bold fs-2 text-center">No Files Present. Please Upload New Files</p>
              </div>
              : <Blocks
                visible={true}
                height="80"
                width="80"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
              />}
          </div>
        )}
      </div>
    </div>
  );
}
