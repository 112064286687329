import axios from 'axios'
import {AuthModel, ForgotPasswordModel, RequestEmailOtp, ResponseModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL
// const API_URL = "https://apps.optira.ai:8000"
// const API_URL = "http://localhost:8000"

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password/`
export const REQUEST_PASSWORD_CHANGE=`${API_URL}/verify_otp_for_password_reset/`

// Server should return AuthModel
export async function login(email: string, password: string) {
  const response = await axios.post<ResponseModel>(`${API_URL}/login/`, {email, password})
  return response.data
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export async function requestPassword(email: string) {
  const response= await axios.post<ForgotPasswordModel>(REQUEST_PASSWORD_URL, {
    email,
  })
  if(response.data.status==true){
    return response.data;
  }else{
    alert(response.data.message);
  }
}

//for verify otp and password change
export async function verifyOtpForPasswordReset(email:string,otp: string,new_password:string) {
  const response= await axios.post<ForgotPasswordModel>(REQUEST_PASSWORD_CHANGE, {
    email,
    otp,
    new_password
  })
  console.log(response.data.status);
  if (response.data.status==true){
    return response.data;
  }else{
    alert(response.data.message);
  }
}


export async function getUserByToken(token: string) {
  const response = await axios.post<ResponseModel>(`${API_URL}/get_user_by_token/`, {token: token})
  return response.data
}

export async function verifyOtpForAccountVerification(email:string,otp:string){
  const response=await axios.post<RequestEmailOtp>(`${API_URL}/verify_otp_for_account_verification/`,{email:email,otp:otp})
  if (response.data.status==true){
    return response.data;
  }else{
    alert(response.data.message);
  }
}

export async function requestOtp(email:string){
  const response=await axios.post<RequestEmailOtp>(`${API_URL}/verify_email/`,{email:email})
  if (response.data.status==true){
    return response.data;
  }else{
    alert(response.data.message);
  }
}

export async function verifyAccountStatus(email:string){
  const response=await axios.post(`${API_URL}/check_account_verified/`,{email:email})
  return response.data;
}