import { Link } from 'react-router-dom'
import { DocumentTypeCard } from '../../modules/dashboard/DocumentTypeCard'
import { useEffect, useState } from 'react';
import { statistic } from '../../api';
import { useAuth } from '../../modules/auth';
import { Blocks } from 'react-loader-spinner';

type Props = {}

const data = [
  {
    link: "invoice",
    svgIcon: "document",
    title: "Invoices",
    description: "Extract invoice ID, customer details, vendor details, ship to, bill to, total tax, subtotal, line items and more.",
    disabbled: false,
  },
  {
    link: "bankstatement",
    svgIcon: "cheque",
    title: "Bank Statements",
    description: "Extract customer ID, customer details, account statement and Dump into csv, sql table etc",
    disabbled: false
  },
  {
    link: "handwritten",
    svgIcon: "message-text",
    title: "Handwritten",
    description: "Extract key value pairs and structure like tables and selection marks from any form or document.",
    disabbled: false
  },
  {
    link: "fax",
    svgIcon: "note",
    title: "Fax",
    description: "Extract invoice ID, customer details, vendor details and contact details from Fax documents.",
    disabbled: false
  },
  {
    link: "",
    svgIcon: "some-files",
    title: "Vouchers",
    description: "Extract Amount and Gift from Vouchers and vendor details from Gift Cards, and Coupons.",
    disabbled: true
  },
  {
    link: "",
    svgIcon: "receipt-square",
    title: "Receipt",
    description: "Extract receipt ID, customer details, amount details and vendor details from receipts.",
    disabbled: true
  },

]

interface ProcessedData {
  processed: number;
  unprocessed: number;
}

interface StatisticsData {
  [key: string]: ProcessedData; // This makes it flexible to accept any key of type string
}


export default function DocumentProcessingDashboard({ }: Props) {

  const { currentUser } = useAuth();
  const [statisticsData, setStatisticsData] = useState<StatisticsData>()
  const [loading,setLoading]=useState<boolean>(false)

  useEffect(() => {
    setLoading(true)
    statistic({ email: currentUser?.email, username: currentUser?.username })
      .then((res) => {
        setLoading(false)
        setStatisticsData(res.data)
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false)
        // Handle API call error if necessary
      });

  }, []);

  return (
    <div className='m-3'>
      {/* begin::Row */}
      <div className='row g-5 g-xl-8'>
        <h1>Document Types</h1>
        {
          loading?(
            <Blocks
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
          />

          ):(
          statisticsData && data.map((doctype) => (
            <div className='col-xl-4'>
              <Link to={(doctype.link === "bankstatement" || doctype.link === "invoice" || doctype.link === "handwritten") ? `/documentprocessing/${doctype.link}` : "#"}>
                <DocumentTypeCard
                  className='card-xl-stretch mb-xl-8'
                  tag={doctype.link}
                  svgIcon={doctype.svgIcon}
                  color='light'
                  iconColor='white'
                  title={doctype.title}
                  titleColor='dark'
                  description={doctype.description}
                  descriptionColor='dark'
                  processed={statisticsData?.[doctype.link]?.processed ?? 0}
                  unprocessed={statisticsData?.[doctype.link]?.unprocessed ?? 0}
                />
              </Link>
            </div>
          
          ))
        )
        }
      </div>
      {/* end::Row */}
    </div>
  )
}