/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useGoogleLogin } from '@react-oauth/google'
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone'
import useDrivePicker from 'react-google-drive-picker'
import { useNavigate, useParams } from 'react-router-dom'
import { KTIcon } from '../../../../_metronic/helpers'
import { downloadGdriveFile, uploadFileDemo } from '../../../api'
import { useAuth } from '../../auth'
import {Spinner} from 'react-bootstrap'


type Props = {
  closeModal?: boolean;
  setRefresh?: Dispatch<SetStateAction<boolean>>;
  setCloseModal?: Dispatch<SetStateAction<boolean>>;
};

const UploadDocuments: React.FC<Props> = ({ closeModal, setRefresh, setCloseModal }) => {
  const [loading, setLoading] = useState(false)
  const { currentUser } = useAuth()
  const { doctype } = useParams()

  const [selectedFiles, setSelectedFiles] = useState<File[]>([])
  const [supportedFiles, setSupportedFiles] = useState<File>()
  const [fileName, setFileName] = useState<Array<string>>([])
  const [driveFiles, setDriveFiles] = useState<Array<string>>([])
  const [openPicker, data] = useDrivePicker()
  const [isPdfFile, setIsPdfFile] = useState<boolean>(true)
  // const customViewsArray = [new google.picker.DocsView()]; // custom view

  // Handle file change from Browse File button
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files) {
      
      const fileList = Array.from(files);
      const supportedFiles = fileList.filter(file =>
        ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'].includes(file.type)
      ); // Filter for supported types
      if (supportedFiles.length+selectedFiles.length>10) {
        alert("You can only upload up to 10 files.");
        return
      }
      if (supportedFiles.length > 0) {
        // Get names of new files only if they aren't already in selected files
        const newFiles = supportedFiles.filter(file =>
          !selectedFiles.some(selectedFile => selectedFile.name === file.name)
        );

        if (newFiles.length > 0) {
          const newFileNames = newFiles.map(file => file.name);

          // Append new files to existing selected files
          setFileName(prevFileNames => [...prevFileNames, ...newFileNames]);
          setSelectedFiles(prevSelectedFiles => [...prevSelectedFiles, ...newFiles]);

          // Optionally set the first supported file (PDF, JPEG, JPG, PNG) as a separate value if needed
          setSupportedFiles(prevSupportedFile => prevSupportedFile || newFiles[0] || null);
        } else {
          console.log('No new supported files were selected');
        }
      } else {
        console.log('No supported files were selected');
      }
      event.target.value = '';
    }
  };

  const handleRemoveFile = (fileToRemove: string) => {
    // Remove the file from the selected files and file names
    setFileName(prevFiles => prevFiles.filter(file => file !== fileToRemove));
    setSelectedFiles(prevFiles => prevFiles.filter(file => file.name !== fileToRemove));
  };

  const handleReset = () => {
    setFileName([]); // Reset the file names
    // setSupportedFiles('');
    setSelectedFiles([]);
    const fileInput = document.getElementById('file-upload') as HTMLInputElement; // Type assertion
    if (fileInput) {
      fileInput.value = ''; // Clear the file input
    }
  };

  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {
      // Handle file changes here
      console.log(acceptedFiles)
      const supportedFiles = acceptedFiles.filter(file =>
        ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'].includes(file.type)
      );

      if (supportedFiles.length > 10) {
        alert('You can only upload up to 10 files')
        return
      }
      if (supportedFiles) {
        const fileList = Array.from(supportedFiles)
        let tempFileNames: string[] = []
        supportedFiles.map((file) => {
          tempFileNames.push(file.name)
        })
        setFileName([...tempFileNames])
        let seletedFile = supportedFiles[0]
        setSupportedFiles(seletedFile)
        setSelectedFiles(fileList)
      }
    },
    []
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop, accept: {
      'application/pdf': [],
      'image/jpeg': [],
      'image/jpg': [],
      'image/png': [],
    }, noKeyboard: true, noClick: true,
  })
  const navigate = useNavigate();
  const handleSubmit = (event: React.FormEvent) => {
    if (setCloseModal) {
      setCloseModal(true)
    }
    setLoading(true)
    event.preventDefault()
    let enableProcess = true


    // // Check if all selected files are PDF
    // const hasNonSupportedFiles = selectedFiles.some(file => !file.name.toLowerCase().endsWith('.pdf'));
    // if (hasNonSupportedFiles) {
    //   setLoading(false);
    //   alert("Unsupported file format. Please upload PDF files only.")
    //   setSelectedFiles([])
    //   setFileName([])

    //   return; // Exit early to prevent further execution

    // } else {

    if (selectedFiles.length > 10) {
      alert('you can upload upto 10 files')
      return
    }

    if (selectedFiles.length > 0) {
      const formData = new FormData()
      let fileArr = []
      selectedFiles.forEach((file, index) => {
        formData.append('files', file, `${file.name}`)
      })
      formData.append('username', currentUser ? currentUser.username : 'demo')
      formData.append('email', currentUser ? currentUser.email : 'demo@thirdeyedata.ai')


      doctype ? formData.append('tag', doctype) : formData.append('tag', 'resume')
      if (enableProcess) {

        uploadFileDemo(formData)
          .then((response) => {
            setLoading(false)
            if (setRefresh) {
              setRefresh(true); // Safely invoke setRefresh
            }
            if (setCloseModal) {
              setCloseModal(false);
            }
            window.location.reload()
            response.json()
          })
          .then((data) => { })
          .catch((error) => {
            // Handle error
            if (setCloseModal) {
              setCloseModal(false);
            }
          })
        if (setCloseModal) {
          setCloseModal(false);
        }
      }
    } else {
      if (setCloseModal) {
        setCloseModal(false);
      }
      setLoading(false)
      alert('You need to upload atleast one file.')
    }
  }

  useEffect(() => {
    console.log(closeModal)
    console.log(fileName)
    if (closeModal) {
      let tempArr: string[] = []
      console.log(fileName)
      setFileName(tempArr)
      setSelectedFiles([])
    }
  }, [closeModal])

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
    >
       <div
       className={loading ? 'd-flex align-items-center justify-content-center' : 'd-none'}
       style={{
         width: '100vw',
         height: '100vh',
         zIndex: 100,
         backgroundColor: '#5e627861',
         position: 'fixed',
         top: 0,
         left: 0,
       }}
     >
        <Spinner animation="border" role="status" variant="light" style={{ width: '3rem', height: '3rem' }}>
        <span className="visually-hidden">Loading...</span>
      </Spinner>
     </div>
      
      <div
        {...getRootProps()}
        style={{

          border: '2px dashed',
          padding: '40px 20px', // Adjust padding for a more flexible design
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '150px', // Set a minimum height for flexibility
          width: '100%', // Full width to accommodate more files
          marginBottom: '20px',
          overflow: 'hidden',
          maxHeight: '375px', // Set a maximum height for vertical scrolling
          maxWidth: '100%',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',


        }}
      >
        <input {...getInputProps()} className='form-control form-control-lg form-control-solid' />
        {isDragActive ? (
          <p>Drop the files here...</p>
        ) : (
          <p>
            {fileName.length <= 0 ? (
              <>
                <span className='fw-bold text-dark'>
                  Please upload your file here. (Only PDF,JPEG,JPG,PNG files are supported.)
                </span>
                <input
                  type='file'
                  accept='.pdf, .jpeg, .jpg, .png'
                  onChange={handleFileChange}
                  style={{ display: 'none' }} // Hide the default file input
                  id='file-upload'
                  multiple // Allow multiple file selection
                />
                <div className="d-flex justify-content-center">
                  <label htmlFor='file-upload' className='btn btn-primary btn-sm'>
                    Browse
                  </label>
                </div>
              </>

            ) : (
              <>
                <div className='d-flex flex-column'>
                  {fileName.map((file: string) => (
                    <div className='badge badge-primary m-1'>
                      <KTIcon iconName='file' className='fs-3' /> &nbsp; {file.length > 20 ? `${file.slice(0, 20)}...` : file}

                      <button type='button' onClick={() => handleRemoveFile(file)} className='btn-close'>
                        <KTIcon iconName='close' className='fs-5' />
                      </button>
                    </div>
                  ))}
                </div>
                <input
                  type='file'
                  accept='.pdf, .jpeg, .jpg, .png'
                  onChange={handleFileChange}
                  style={{ display: 'none' }} // Hide the default file input
                  id='file-upload'
                  disabled={loading}
                  multiple // Allow multiple file selection
                />
                <div className="d-flex justify-content-center">
                  <label htmlFor='file-upload' className='btn btn-primary btn-sm' >
                    Browse
                  </label>
                </div>
              </>
            )}
          </p>
        )}
      </div>
      {/* begin::Form group */}
      <div className='text-center mt-5'>
        <button
          type='submit'
          className='btn btn-lg btn-success w-100 mb-5'
          onClick={handleSubmit}
          disabled={loading}
        >
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...{' '}
              {/* <span className='spinner-border spinner-border-sm align-middle ms-2'></span> */}
            </span>
          )}
        </button>
        <button type='button' className='btn btn-lg btn-secondary text-white w-100 mb-5 ' onClick={handleReset}>
          Reset
        </button>
      </div>

      {/* end::Form group */}
    </form>
  )
}

export { UploadDocuments }
