import React from 'react';
import { KTIcon } from '../../../../_metronic/helpers';
import { DocumentPreviewProps } from '../DocumentProcessingModel';
import { useAuth } from '../../auth';
// import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { Document, Page } from 'react-pdf';

export const DocumentPreview: React.FC<DocumentPreviewProps> = ({
  fileName,
  doctype,
  fileLink,
}) => {

  console.log("filelink", fileLink)
  const { currentUser } = useAuth();
  const isImage = /\.(jpeg|jpg|png|bmp|webp)$/i.test(fileLink);

  console.log("filename",fileName)
  const generateIframeContent = () => {
    return `
          <html>
            <head>
              <style>
                body {
                  margin: 0;
                  padding: 0;
                  overflow: auto; /* Allow scrolling inside the iframe */
                  height: 100%;
                }
                img {
                  display: block;
                  max-width: 100%;  /* Make the image responsive */
                  height: auto;
                  margin: 0 auto;
                  object-fit: contain; /* Keep the image aspect ratio intact */
                }
              </style>
            </head>
            <body>
              <img src="${fileLink}" />
            </body>
          </html>
        `;
  };
  // Google Docs Viewer link for PDFs
  let googleViewerLink = `https://docs.google.com/viewer?url=${encodeURIComponent(fileLink)}&embedded=true`;
  return (
    <div className="col-sm-12 col-md-6">
      <div className="card card-xl-stretch" style={{ height: "70vh" }}>
        <div className="card-header border-0">
          <h3 className="card-title fw-bold text-dark">
            <KTIcon iconName="screen" className="fs-2x text-primary" />
            &nbsp; Preview Original Document
          </h3>
        </div>
        {fileLink !== '' ? (
          isImage ? (
            <iframe
              srcDoc={generateIframeContent()}
              title="PDF Preview"
              width="100%"
              height="100%"

              style={{
                display: 'block',
                margin: '0 auto',
                overflow: 'auto', // Enable scroll
              }}
            ></iframe>) : (
                <iframe
                src={googleViewerLink}
                title="PDF Preview"
                width="100%"
                height="500px"
            ></iframe>

            // <iframe
            //   src={`/media/preview/${fileName}`}
            //   title="PDF Preview"
            //   width="100%"
            //   height="500px"
            // ></iframe>

            //     <div style={{ height: '500px', width: '100%' ,overflowY:'auto'}}>
            //     <Document file={fileLink}>
            //         <Page pageNumber={1} />
            //     </Document>
            // </div>
          )
        ) : (
          <div className="card-body">
            <h4 className="text-center">Select a document to preview it here</h4>
          </div>
        )}
      </div>
    </div>
  )
};