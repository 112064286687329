import clsx from 'clsx';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { requestPassword, verifyOtpForPasswordReset } from '../core/_requests';

// Main ForgotPassword Component
export function ForgotPassword() {

  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);
  const [otpSent, setOtpSent] = useState(false);
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const[passwordChangeSuccess,setPasswordChangeSuccess]=useState(false);
  const navigate = useNavigate();
  const initialValues = { email: '', otp: '', newPassword: '', confirmPassword: '' };

  // Define validation schemas for different stages
  const emailValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email format")
      .min(3, "Minimum 3 characters")
      .max(50, "Maximum 50 characters")
      .required("Email is required"),
  });

  const otpAndPasswordValidationSchema = Yup.object().shape({
    otp: Yup.string().required("OTP is required"),
    newPassword: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .max(20, "Password must not exceed 20 characters")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[0-9]/, "Password must contain at least one number")
    .matches(/[@$!%*?&#]/, "Password must contain at least one special character")
    .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword")], "Passwords must match")
      .required("Confirm password is required"),
  });

  const validationSchema = otpSent ? otpAndPasswordValidationSchema : emailValidationSchema;

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      if (!otpSent) {
        // Request OTP
        setLoading(true);
        requestPassword(values.email)
          .then((res) => {
            console.log(res);
            setOtpSent(true);
            setHasErrors(false);
            setLoading(false);
            setSubmissionSuccess(true);
          })
          .catch(() => {
            setHasErrors(true);
            setLoading(false);
            setSubmitting(false);
          });
      } else {
        // Change password with OTP
        setLoading(true);
        verifyOtpForPasswordReset(values.email, values.otp, values.newPassword)
          .then((res) => {
              // alert('Password changed successfully!');
              setPasswordChangeSuccess(true);
              setSubmitting(false);
              setLoading(false);
              setSubmissionSuccess(true);
              setTimeout(() => {
                navigate('/auth')
              }, 5000);
          })
          .catch(() => {
            alert('Error changing password or invalid OTP');
            setSubmitting(false);
            setLoading(false);
          });
      }
    },
  });

  const getPasswordStrength = (password: string) => {
    let strength = 0;
    if (password.length >= 8) strength += 1;
    if (/[A-Z]/.test(password)) strength += 1;
    if (/[a-z]/.test(password)) strength += 1;
    if (/[0-9]/.test(password)) strength += 1;
    if (/[@$!%*?&#]/.test(password)) strength += 1;
    return strength;
  };
  const strength = getPasswordStrength(formik.values.newPassword);
  return (
    <form className="form w-100" noValidate onSubmit={formik.handleSubmit}>
      <div className="text-center mb-10">
        <h1 className="text-dark fw-bolder mb-3">
          Forgot Password
          
        </h1>
        <div className="text-gray-500 fw-semibold fs-6">
          {!otpSent
            ? 'Enter your email to reset your password.'
            : 'OTP has been sent to your email. Enter the OTP along with your new password.'}
        </div>
      </div>

      {hasErrors && (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">Sorry, there was an error. Please try again.</div>
        </div>
      )}

      {/* Email Field */}
      {!otpSent && (
        <div className="fv-row mb-8">
          <label className="form-label fw-bolder text-gray-900 fs-6">Email</label>
          <input
            type="email"
            {...formik.getFieldProps('email')}
            className={clsx('form-control bg-transparent', {
              'is-invalid': formik.touched.email && formik.errors.email,
              'is-valid': formik.touched.email && !formik.errors.email,
            })}
            disabled={otpSent}
          />
          {formik.touched.email && formik.errors.email && (
            <div className="fv-help-block">
              <span role="alert">{formik.errors.email}</span>
            </div>
          )}
        </div>
      )}

      {/* OTP Field + New Password and Confirm Password */}
      {otpSent && (
        <>
          <div className="fv-row mb-8">
            <label className="form-label fw-bolder text-gray-900 fs-6">Enter OTP</label>
            <input
              type="text"
              {...formik.getFieldProps('otp')}
              className={clsx('form-control bg-transparent', {
                'is-invalid': formik.touched.otp && formik.errors.otp,
                'is-valid': formik.touched.otp && !formik.errors.otp,
              })}
            />
            {formik.touched.otp && formik.errors.otp && (
              <div className="fv-help-block">
                <span role="alert">{formik.errors.otp}</span>
              </div>
            )}
          </div>

          <div className="fv-row mb-8">
            <label className="form-label fw-bolder text-gray-900 fs-6">New Password</label>
            <input
              type="password"
              {...formik.getFieldProps('newPassword')}
              className={clsx('form-control bg-transparent', {
                'is-invalid': formik.touched.newPassword && formik.errors.newPassword,
                'is-valid': formik.touched.newPassword && !formik.errors.newPassword,
              })}
            />
            {formik.touched.newPassword && formik.errors.newPassword && (
              <div className="fv-help-block">
                <span role="alert">{formik.errors.newPassword}</span>
              </div>
            )}
          </div>
          {/*start meter*/}
       
       <div className="d-flex align-items-center mb-3" data-kt-password-meter-control="highlight">
            <div className={`flex-grow-1 rounded h-5px me-2 ${strength > 0 ? 'bg-success' : 'bg-secondary'}`}></div>
            <div className={`flex-grow-1 rounded h-5px me-2 ${strength > 1 ? 'bg-success' : 'bg-secondary'}`}></div>
            <div className={`flex-grow-1 rounded h-5px me-2 ${strength > 2 ? 'bg-success' : 'bg-secondary'}`}></div>
            <div className={`flex-grow-1 rounded h-5px ${strength > 3 ? 'bg-success' : 'bg-secondary'}`}></div>
          </div>
         
        
          {/* end::Meter */}
        <div className="text-muted">
          Use 8 or more characters with a mix of letters, numbers & symbols.
        </div>
        
     
          <div className="fv-row mb-8">
            <label className="form-label fw-bolder text-gray-900 fs-6">Confirm Password</label>
            <input
              type="password"
              {...formik.getFieldProps('confirmPassword')}
              className={clsx('form-control bg-transparent', {
                'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword,
                'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
              })}
            />
            {formik.touched.confirmPassword && formik.errors.confirmPassword && (
              <div className="fv-help-block">
                <span role="alert">{formik.errors.confirmPassword}</span>
              </div>
            )}
          </div>
        </>
      )}
      {passwordChangeSuccess &&(
            <div className="alert alert-success" role="alert">
              <strong>Password has been changed successfully!</strong>
              <p>You will be redirected to the login page in 5 seconds.</p>
            </div>
      )}

      {/* Buttons */}
      <div className="d-flex flex-wrap justify-content-center pb-lg-0">
      {/* Submit Email Button */}
      {!otpSent && (
        <>
          <button
            type="submit"
            className="btn btn-success me-4"
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading ? (<span className="indicator-label d-flex align-items-center">Submit</span>) 
            : (<span className="indicator-progress" style={{ display: 'block' }}>
                  Submitting <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
              )}
          </button>

        </>
      )}
        {/* Change Password Button */}
        {otpSent && (
          <>
            <button type="submit" className="btn btn-success" disabled={!formik.isValid}>
            {!loading ? (<span className="indicator-label d-flex align-items-center">Change Password</span>) 
            : (<span className="indicator-progress" style={{ display: 'block' }}>
                  Processing <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
              )}
          
            </button>
            
            
          </>
        )}
      </div>
    </form>
     

  );
}
