import React, { useState } from 'react'
import { KTIcon } from '../../../_metronic/helpers'
import { documentApproval } from '../../api'
import { useAuth } from '../auth';

type Props = {
    selectedFiles: string[];
    setSelectedFiles: React.Dispatch<React.SetStateAction<string[]>>;
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
    tag: string;
    setIsApproval:React.Dispatch<React.SetStateAction<string>>;
    setProcessAction:React.Dispatch<React.SetStateAction<string>>;
}

function Feedback({ selectedFiles, setSelectedFiles, setRefresh, tag ,setIsApproval,setProcessAction}: Props) {
    const { currentUser } = useAuth();

    const [feedback, setFeedback] = useState<string>()
    // const [processAction, setProcessAction] = useState<string | null>(null)
    const [charCount, setCharCount] = useState<number>(0);
    const maxChars = 255;


    const handleChange = (e: any) => {
        const currentCharCount = e.target.value.length;
    
        if (currentCharCount <= maxChars) {  
            setFeedback(e.target.value);
            setCharCount(currentCharCount);  
        } else {
            alert('Only ' + maxChars + ' characters are allowed.');
        }
    }


    const handleSubmitFeedback = (e: any) => {
         e.preventDefault()
        
        setIsApproval('');
        setFeedback(e.target.value);
        if (selectedFiles.length > 0) {
            setProcessAction('rejected')
            let files: Array<Object> = [];
            selectedFiles.forEach((selectedFile) => {
                let fileObj = {
                    file_url: selectedFile
                };
                files.push(fileObj);
            });
            if (tag) {
                documentApproval({
                    useremail: currentUser?.email,
                    files: files,
                    approval: 'rejected',
                    feedback: feedback ? feedback : "",
                    userName: currentUser?.username,
                    tag: tag,
                }).then((res) => {
                    setProcessAction('')
                    alert(res.message)
                    setIsApproval('rejected')
                })
            } else {
                alert("No file selected")
            }
        }
    }

    return (
        <div className="modal-body scroll-y p-2">
            <div
                className={`card card-xl-stretch mb-2 px-2`}
                style={{ maxHeight: "60vh", overflowY: "scroll" }}
            >
                <div className="row">
                    <div className="col-12">
                        <div className="row mb-6">
                            {/* <label className="col-12 col-form-label fw-bold fs-2">
                                                    <span className="px-1">Feedback</span>
                                                </label> */}

                            <div className="col-12 fv-row">
                                {/* <small className="px-1 fs-8 mb-1">Based on the extracted data provide feedback</small> */}
                                <textarea
                                    value={feedback}
                                    onChange={(e)=>handleChange(e)}
                                    className='form-control form-control-solid mb-2'
                                    rows={3}
                                    placeholder='Type your comments here' />
                                    <h5>{maxChars - charCount} Characters remaining</h5>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="my-2 px-2 text-center d-flex justify-content-end">
                <button
                    className="btn btn-lg btn-success"
                    data-bs-dismiss="modal"
                    onClick={(e) => handleSubmitFeedback(e)}
                >
                    Give Feedback
                </button>
            </div>
        </div>

    )
}

export default Feedback