/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { KTIcon } from '../../../../_metronic/helpers'
import { getAllMember } from '../../../api'
import { useAuth } from '../../auth'
import { DocumentDataObject, TeamMemberObject } from '../DocumentProcessingModel'
import { doc } from 'prettier'
import { useParams } from 'react-router-dom'
type Props = {
    className: string,
    type: string;
    preview: boolean;
    processing: boolean;
    documentData: DocumentDataObject[];
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
    setFileName: React.Dispatch<React.SetStateAction<string>>;
    setProcessDate: React.Dispatch<React.SetStateAction<string>>;
    setSelectedFiles: (files: string[]) => void;
    toggleState: () => void;
    tab: string;
    indexing: boolean;
    onReview: (fileLink: string, approval: string, fileOriginalName: string) => void;
    handleDownload: (fileLink: string, format: string, originalFileName: string, processDate: string) => void;
    handleDelete: (userName: string, tag: string, fileUrl: string) => void;

}



const DocumentTable: React.FC<Props> = ({ className, documentData, type, setRefresh, preview, toggleState, setFileName, onReview, setSelectedFiles, handleDownload, handleDelete, processing, indexing, tab, setProcessDate }) => {
    const { doctype } = useParams()
    const [data, setData] = useState<DocumentDataObject[]>([])
    const [teams, setTeams] = useState<string[]>()
    const [selectedFilesTable, setSelectedFilesTable] = useState<string[]>([]);
    const [selectedFileNames, setSelectedFileNames] = useState<string[]>([]);
    // const [fileName, setFileName] = useState<string>("");
    const [fileLink, setFileLink] = useState<string>()
    const [feedback, setFeedback] = useState<string>()
    const [teamMember, setTeamMember] = useState<string>()
    const [processingFile, setProcessingFile] = useState<string>()
    // const [processing, setProcessing] = useState<boolean>(false)
    const [downloadFormat, setDownloadFormat] = useState('csv')
    const [showDropdown, setShowDropdown] = useState<string>(); // State to manage dropdown visibility
    const [showFeedback, setShowFeedback] = useState('');
    const [showDropdownExport,setShowDropdownExport]=useState<string>();

    const toggleDropdown = (file_url: string) => {
        setShowDropdown(showDropdown === file_url ? '' : file_url); // Toggles the dropdown for the corresponding file_url
    };

    const toggleDropdownExport = (file_url: string) => {
        setShowDropdownExport(showDropdownExport === file_url ? '' : file_url); // Toggles the dropdown for the corresponding file_url
    };
    

    const handleButtonClick = (file_url: string) => {
        toggleDropdown(file_url); // Toggle the dropdown visibility
    };

    const handleExportClick=(file_url:string)=>{
        toggleDropdownExport(file_url)
      }
    const handleOptionClick = () => {
        setShowDropdownExport("");
        setShowDropdown(""); // Close the dropdown when an option is clicked
    };

    const { currentUser } = useAuth();

    const handleSelect = (
        e: any,
        url: string,
        name: string,
        processed: number
    ) => {
        console.log(e.target.checked, url);

        if (e.target.checked) {
            if (selectedFilesTable.length >= 5) {
                alert("You can only select up to 5 Documents.");
                e.preventDefault(); // Prevents checkbox from being checked
                return;
            }
            let tempSelectedFileNames = selectedFileNames;
            tempSelectedFileNames.push(name);
            setSelectedFileNames([...tempSelectedFileNames]);
            selectedFilesTable.push(url);
            setFileName(name);
            // if (processed == 1 && selectedFilesTable.length <= 1) {
            //   handleProcess();
            // }
        }
        if (!e.target.checked) {
            setFileName("");
            // setResult({});
            const index = selectedFilesTable.indexOf(url);
            if (index > -1) {
                selectedFilesTable.splice(index, 1);
                let tempSelectedFileNames = selectedFileNames;
                tempSelectedFileNames.splice(index, 1);
                setSelectedFileNames([...tempSelectedFileNames]);
            }
        }
        setSelectedFiles(selectedFilesTable)
    };
    console.log("selected files are:", selectedFilesTable)
    const handleToggle = () => {
        toggleState(); // Toggle the state in the parent component
    };

    useEffect(() => {
        setSelectedFilesTable([])
        setSelectedFiles([])
    }, [processing, indexing])

    useEffect(() => {
        setSelectedFilesTable([])
        setSelectedFiles([])
    }, [tab])

    // useEffect(() => {
    //     getAllMember({ organizationId: currentUser?.organizationId }).then((res) => {
    //         //  Filtering out emails of objects with type !== 'superadmin'
    //         const emails = res.data
    //             .filter((user: TeamMemberObject) => user.type !== 'superadmin')
    //             .map((user: TeamMemberObject) => user.email);

    //         setTeams(emails)
    //     });
    // }, [])

    return (
        <div>
            {/* Feedback Modal */}
            <div
                className="modal fade"
                id="kt_modal_show_feedback"
                aria-hidden="true"
            >
                {/* begin::Modal dialog */}
                <div className="modal-dialog modal-dialog-centered mw-650px">
                    {/* begin::Modal content */}
                    <div className="modal-content">
                        <div className="modal-header">
                            {/* begin::Modal title */}
                            <h2 className="fw-bolder">Document Feedback</h2>
                            {/* end::Modal title */}

                            {/* begin::Close */}
                            <button
                                className="btn btn-sm btn-icon btn-active-color-primary"
                                data-bs-dismiss="modal"
                                type='button'
                            >
                                <KTIcon iconName="cross" className="fs-1" />
                            </button>
                            {/* end::Close */}
                        </div>
                        {/* begin::Modal body */}
                        <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
                            {
                                (showFeedback == '' || showFeedback == null) ? <p className="fs-1 fw-bold text-center">No Feedback Provided</p> :
                                    <p className="fs-4">{showFeedback}</p>
                            }
                        </div>
                        {/* end::Modal body */}
                    </div>
                    {/* end::Modal content */}
                </div>
                {/* end::Modal dialog */}
            </div>

            <div className={`card ${className}`} style={{ boxShadow: '6px 6px 19px #0c101b, -6px -6px 19px #1c263f' }} >
                <div className='card-body  py-2 '>
                    <div className='table-responsive' style={{ maxHeight: 'calc(100vh - 350px)', overflowY: 'auto', scrollbarColor: 'grey transparent' }}>
                        <table className='table table-row-bordered table-row-gray-200 align-middle gs-0 gy-4'>
                            <thead className='position-sticky top-0 ' style={{ zIndex: 1,
                                 backgroundColor: '#1c263f'
                                  }}>
                                <tr className='fw-bold  text-white-50  p-2'>
                                    {(documentData.some(doc => doc.processed === 0)) &&
                                        <th className='ps-4 min-w-50px'></th>
                                    }
                                    <th className='min-w-100px'>Documents</th>
                                    {documentData.length > 0 && (
                                        <th className='min-w-200px'>
                                            {documentData.some(doc => doc.processed === 0) ? 'Created At' : 'Processed Date'}
                                        </th>
                                    )}
                                    <th className='min-w-200px'>Assigned To</th>
                                    {/* <th className='min-w-150px'>Status</th> */}
                                    <th className='min-w-150px'>Approval</th>
                                    <th className='min-w-250px text-end pe-6'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    documentData.length > 0 && documentData.map((doc, index) => (
                                        <tr key={index}>
                                            {
                                                (doc.processed == 0) &&
                                                <td className='ps-4'>
                                                    <input
                                                        className="form-check-input bg-light border-4 border-gray-400 border-start border-top"
                                                        type="checkbox"
                                                        value=""
                                                        checked={selectedFilesTable.includes(doc.file_url)}
                                                        onClick={(e) =>
                                                            handleSelect(
                                                                e,
                                                                doc.file_url,
                                                                doc.file_original_name,
                                                                doc.processed
                                                            )
                                                        }

                                                    />
                                                </td>
                                            }

                                            <td>
                                                <div className='d-flex align-items-center justify-content-start'>
                                                    <div className='d-flex justify-content-start flex-column'>
                                                        <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'

                                                            onClick={(e) => {
                                                                if (tab == 'processed') {
                                                                    setFileLink(doc.file_url);
                                                                    setFileName(doc.file_original_name);
                                                                    setProcessDate(doc.process_date);
                                                                    handleToggle();
                                                                    onReview(doc.file_url, doc.approval, doc.file_original_name)
                                                                }
                                                            }}>
                                                            {(doc.file_original_name.lastIndexOf('.') > -1 && doc.file_original_name.lastIndexOf('.') <= 20) ? doc.file_original_name.substring(0, doc.file_original_name.lastIndexOf('.')) : doc.file_original_name.substring(0, 20)}

                                                        </a>
                                                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                                            {doc.tag}
                                                        </span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                {doc.processed == 0 ?
                                                    <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                        {new Date(doc.upload_date).toLocaleString()}
                                                    </a>
                                                    :
                                                    <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                        {new Date(doc.process_date).toLocaleString()}
                                                    </a>
                                                }
                                                {/* <span className='text-muted fw-semibold text-muted d-block fs-7'>Paid</span> */}
                                            </td>
                                            <td>
                                                {doc.assign_to == "" || doc.assign_to == null ?
                                                    <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                        Not Assigned
                                                    </a> :
                                                    <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                        {doc.assign_to}
                                                    </a>
                                                }
                                            </td>
                                            {/* <td>
                                                {
                                                    doc.processed == 1 ?
                                                        <span className='badge badge-light-success'>Proccessed</span>
                                                        : <span className='badge badge-light-danger'>Unprocessed</span>
                                                }
                                            </td> */}
                                            <td>
                                                {
                                                    doc.approval == "pending" || doc.approval == "" || doc.approval == null ?
                                                        <span className='badge badge-light-warning fs-4'>Pending</span>
                                                        : doc.approval == "accepted" ?
                                                            <span className='badge badge-light-success fs-4'>Accepted</span>
                                                            : <span className='badge badge-light-danger fs-4'>Rejected</span>
                                                }
                                            </td>
                                            <td className='text-end'>
                                                {/* {
                                                    doc.processed === 0 &&
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary me-3 my-3 w-180px "
                                                    >
                                                        Process
                                                    </button>
                                                } */}
                                                <button
                                                    type="button"
                                                    className="btn btn-primary btn-sm me-3 my-3 w-100px fs-8"
                                                    disabled={selectedFilesTable.length > 0}
                                                    onClick={(e) => { setFileLink(doc.file_url); setFileName(doc.file_original_name); setProcessDate(doc.process_date); handleToggle(); onReview(doc.file_url, doc.approval, doc.file_original_name) }}
                                                >
                                                    {doc.processed == 1 ? "Review" : "Process"}
                                                </button>

                                                {
                                                    doc.processed == 1 && <>
                                                        {/* {selectedRow === index && ( */}
                                                        <span style={{ position: 'relative', display: 'inline-block', }}>
                                                            <button

                                                                onClick={() => handleButtonClick(doc.file_url)}
                                                                type='button'
                                                                className='btn btn-dark btn-sm  '
                                                                disabled={selectedFilesTable.length > 0}
                                                            >
                                                                Download
                                                                <KTIcon iconName='down' className='fs-5 m-0' />
                                                            </button>
                                                            {showDropdown === doc.file_url && (
                                                                <div
                                                                    style={{ position: 'absolute', top: 'calc(100% + 5px)', left: 0, zIndex: 10 }}
                                                                    className='btn btn-light btn-sm'
                                                                >
                                                                    <div className='menu-item px-3'>
                                                                        <a
                                                                            className='menu-link px-3'
                                                                            onClick={(e) => { setShowDropdown(""); handleDownload("csv", doc.file_url, doc.file_original_name, doc.process_date) }}
                                                                        >
                                                                            CSV
                                                                        </a>
                                                                    </div>
                                                                    <div className='menu-item px-3'>
                                                                        <a
                                                                            className='menu-link px-3'
                                                                            onClick={(e) => { setShowDropdown(""); handleDownload("xlsx", doc.file_url, doc.file_original_name, doc.process_date) }}
                                                                        >
                                                                            XLSX
                                                                        </a>
                                                                    </div>
                                                                    <div className='menu-item px-3'>
                                                                        <a
                                                                            className='menu-link px-3'
                                                                            onClick={(e) => { setShowDropdown(""); handleDownload("json", doc.file_url, doc.file_original_name, doc.process_date) }}
                                                                        >
                                                                            JSON
                                                                        </a>
                                                                    </div>
                                                                    <div className='menu-item px-3'>
                                                                        <a
                                                                            className='menu-link px-3'
                                                                            onClick={(e) => { setShowDropdown(""); handleDownload("txt", doc.file_url, doc.file_original_name, doc.process_date) }}
                                                                        >
                                                                            TXT
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </span>


                                                        {/* export to */}

                                                        <span style={{ position: 'relative', display: 'inline-block', marginLeft: '1rem' }}>
                                                            <button

                                                                onClick={() => handleExportClick(doc.file_url)}
                                                                type='button'
                                                                className='btn btn-info btn-sm  '

                                                            >
                                                                Export To
                                                                <KTIcon iconName='down' className='fs-5 m-0' />
                                                            </button>
                                                            {showDropdownExport === doc.file_url && (
                                                                <div
                                                                    style={{ position: 'absolute', top: 'calc(100% + 5px)', left: 0, zIndex: 10 }}
                                                                    className='btn btn-light btn-sm '
                                                                >
                                                                    <div className='menu-item px-3'>
                                                                        <a
                                                                            className='menu-link px-3'
                                                                            onClick={(e) => { setShowDropdownExport(""); }}
                                                                        >
                                                                            SQL
                                                                        </a>
                                                                    </div>
                                                                    <div className='menu-item px-3'>
                                                                        <a
                                                                            className='menu-link px-3'
                                                                            onClick={(e) => { setShowDropdownExport(""); }}
                                                                        >
                                                                            ERP
                                                                        </a>
                                                                    </div>
                                                                    <div className='menu-item px-3'>
                                                                        <a
                                                                            className='menu-link px-3'
                                                                            onClick={(e) => { setShowDropdownExport(""); }}
                                                                        >
                                                                            HANA
                                                                        </a>
                                                                    </div>
                                                                    <div className='menu-item px-1'>
                                                                        <a
                                                                            className='menu-link px-1'
                                                                            onClick={(e) => { setShowDropdownExport(""); }}
                                                                        >
                                                                            POWER PLATFORM
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </span>

                                                        {/* )} */}
                                                        {/* <button
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#kt_modal_show_feedback"
                                                            onClick={(e) => {
                                                                setShowFeedback(doc.feedback)
                                                            }}
                                                            type='button'
                                                            disabled={doc.feedback == null ? true : false}
                                                            className={`btn btn-icon btn-icon-muted ${(doc.feedback === null || doc.feedback == '' ) ? 'btn-secondary' : 'btn-warning'} btn-sm w-120px fs-8 mx-1`}
                                                        >
                                                            <KTIcon iconName='information' className='fs-4 fw-bolder' />
                                                        </button> */}
                                                    </>
                                                }
                                                {
                                                    <button
                                                        onClick={() => handleDelete(currentUser?.username || '', doc.tag, doc.file_url)}
                                                        type="button"
                                                        className="btn btn-danger btn-sm fs-8 ms-3"
                                                        disabled={currentUser?.subscribed !== 'premium' || selectedFilesTable.length > 0}
                                                    >
                                                        Delete
                                                    </button>
                                                }
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { DocumentTable }

