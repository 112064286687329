import clsx from 'clsx';
import { useFormik } from 'formik';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Blocks } from 'react-loader-spinner';
import * as Yup from 'yup';
import { verifyAccountStatus, requestOtp, verifyOtpForAccountVerification } from '../core/_requests';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';


export function VerifyEmail() {
    const { email } = useParams();
    console.log(email);

    const [loading, setLoading] = useState(false);
    const [hasErrors, setHasErrors] = useState<string | undefined>(undefined);
    const [otpSent, setOtpSent] = useState(false);
    const [submissionSuccess, setSubmissionSuccess] = useState(false);
    const navigate = useNavigate();
    const [startLoading, setStartLoading] = useState<boolean>(false);
    // Validation schema for OTP only
    const otpValidationSchema = Yup.object().shape({
        otp: Yup.string().required("OTP is required"),
    });

    const formik = useFormik({
        initialValues: { otp: '' },
        validationSchema: otpValidationSchema,
        onSubmit: (values, { setSubmitting }) => {
            setLoading(true);
            if (email) {
                verifyOtpForAccountVerification(email, values.otp)
                    .then((response) => {
                        setSubmissionSuccess(true);
                        setHasErrors(undefined); // Clear any previous errors
                        setTimeout(() => {
                            navigate('/auth'); // Redirect after 5 seconds
                        }, 5000);


                    })
                    .catch(() => {

                        setHasErrors('Invalid OTP. Please check and try again.');
                        setLoading(false);
                    });
            } else {
                alert("Email issue");
            }
        },
    });

    useEffect(() => {
        if (email) {

            // Check verification status API
            verifyAccountStatus(email)
                .then((response) => {
                    if (response.status_code==200 && response.status == false) {
                        setLoading(true);
                        setStartLoading(true);
                        // If not verified, request OTP
                        requestOtp(email)
                            .then(() => {
                                console.log("Request OTP successful");
                                setOtpSent(true); // Show OTP input field
                                setHasErrors(undefined); // Clear any errors from previous attempts
                                setLoading(false);
                            })
                            .catch(() => {
                                setHasErrors("Error sending OTP. Please try again."); // Show error message if OTP request fails
                                setLoading(false);
                            });
                    } else if (response.status_code==200 && response.status == true){
                        // If already verified, navigate to "Account Verified" page
                        setLoading(false);
                        navigate('/already-verified');
                    }else{
                        alert(response.message)
                        setTimeout(()=>{
                            navigate('/auth/')
                        },3000)
                    }
                })
                .catch(() => {
                    setHasErrors("Error checking verification status. Please try again.");
                    setLoading(false);
                    setStartLoading(false);
                });
        }
    }, [email, navigate]);


    return (


        <div
            className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
            style={{
                backgroundImage: `url(${toAbsoluteUrl(
                    "/media/illustrations/sketchy-1/14.png"
                )})`,
            }}
        >
            <div className="d-flex flex-column flex-column-fluid p-10 pb-lg-20">
                <a href="https://optira.ai/" target="_blank" rel="noreferrer">
                    <div className="d-flex align-items-start mb-2">
                        <img
                            alt="Logo"
                            src={toAbsoluteUrl("/media/logos/default.png")}
                            className="h-65px"
                        />
                        <div className="d-flex flex-column ml-3">
                            <span className="fw-bolder fs-1 text-primary">Optira</span>
                            <span className="fs-6 text-white">AI-based Document Processing & Analytics Platform</span>
                        </div>
                    </div>
                </a>

                <div
                    className="w-lg-500px bg-body rounded p-10 p-lg-15 mx-auto"
                    style={{ boxShadow: '6px 6px 19px #0c101b, -6px -6px 19px #1c263f' }}
                >
                    {startLoading ? (
                        <form className="form w-100 mt-4" noValidate onSubmit={formik.handleSubmit}>
                            <div className="text-center mb-10">
                                <h1 className="text-dark fw-bolder mb-3">Verify Account</h1>
                                <div className="text-white-500 fw-semibold fs-6">
                                    {!otpSent && loading ? 'Sending OTP...' :
                                        (otpSent ? 'Enter the OTP sent to your email.' :
                                            (submissionSuccess ? 'Processing...' : ''))}
                                </div>
                                <div>
                                    {!submissionSuccess && loading && (
                                        <div className="spinner-border text-success" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {hasErrors && (
                                <div className="mb-lg-15 alert alert-danger">
                                    <div className="alert-text font-weight-bold">{hasErrors}</div>
                                </div>
                            )}

                            {otpSent && (
                                <div className="fv-row mb-8">
                                    <label className="form-label fw-bolder text-gray-900 fs-6">Enter OTP</label>
                                    <input
                                        type="text"
                                        disabled={submissionSuccess}
                                        {...formik.getFieldProps('otp')}
                                        onChange={(e) => {
                                            formik.handleChange(e);
                                            setHasErrors(undefined); // Clear error when typing
                                        }}
                                        className={clsx('form-control bg-transparent', {
                                            'is-invalid': formik.touched.otp && formik.errors.otp,
                                            'is-valid': formik.touched.otp && !formik.errors.otp,
                                        })}
                                    />
                                    {formik.touched.otp && formik.errors.otp && (
                                        <div className="fv-help-block">
                                            <span role="alert">{formik.errors.otp}</span>
                                        </div>
                                    )}
                                </div>
                            )}

                            {submissionSuccess && (
                                <div className="alert alert-success" role="alert">
                                    <strong>Account verified successfully!</strong>
                                    <p>You will be redirected to the login page in 5 seconds.</p>
                                </div>
                            )}

                            {otpSent && (
                                <button type="submit" className="btn btn-success" disabled={submissionSuccess}>
                                    {!loading ? (
                                        <span className="indicator-label d-flex align-items-center">Verify OTP</span>
                                    ) : (
                                        !submissionSuccess ? (
                                            <span className="indicator-progress" style={{ display: 'block' }}>
                                                Verifying..
                                            </span>) : (
                                            <span className="indicator-progress" style={{ display: 'block' }}>
                                                Verified
                                            </span>
                                        )
                                    )}
                                </button>
                            )}


                        </form>) : (

                        <div className='d-flex align-center justify-content-center'>
                        <Blocks
                            visible={true}
                            height="80"
                            width="80"
                            ariaLabel="blocks-loading"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                        />
                        </div>
                    )
                    }
                </div>
            </div>

            <div className="p-10">
                <div className="row">
                    <div className="col-4">
                        <div className="">
                            <div className="d-flex fw-bold fs-4 align-items-start my-2">
                                <span>A Real-world AI Product by
                                    <a
                                        href="https://thirdeyedata.ai/"
                                        target="_blank"
                                        className="text-hover-primary px-2"
                                        style={{ color: "#fff", textTransform: "uppercase", fontWeight: "bold" }}
                                        rel="noreferrer"
                                    >
                                        <img src={toAbsoluteUrl("/media/logos/thirdeyelogo-horizontal.png")} alt="Thirdeye data" height={25} className="mb-3" /> &nbsp;
                                    </a>
                                </span>
                            </div>
                            <span className="fw-light fs-7">All Rights Reserved © 2024 - ThirdEye Data (USA)</span>
                        </div>
                    </div>
                    <div className="col-4 d-flex align-items-center justify-content-center">
                        <div className="d-flex align-items-center fw-bold fs-2">
                            <a
                                href="https://optira.ai/"
                                target="_blank"
                                className="text-hover-primary px-2"
                                style={{ color: "#fff" }}
                                rel="noreferrer"
                            >
                                Sales
                            </a>

                            <a
                                href="https://optira.ai/submit-ticket/"
                                target="_blank"
                                className="text-hover-primary px-2"
                                style={{ color: "#fff" }}
                                rel="noreferrer"
                            >
                                Support
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
