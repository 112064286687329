/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { KTIcon } from '../../../_metronic/helpers'
import { getAllMember } from '../../api'
import { useAuth } from '../auth'
import { DocumentDataObject, TeamMemberObject } from './DocumentProcessingModel'


type Props = {
    className: string,
    type: string;
    preview: boolean;
    documentData: DocumentDataObject[];
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
    setAudioFile: React.Dispatch<React.SetStateAction<string>>;
    setTranscription: React.Dispatch<React.SetStateAction<string>>;
    setFileName: React.Dispatch<React.SetStateAction<string>>;
    setSelectedFiles: React.Dispatch<React.SetStateAction<string[]>>;
    toggleState: () => void;
    onReview: (fileLink: string,approval:string) => void;
    handleDownload: (fileLink: string, format: string,originalFileName:string,processDate:string) => void;
}



const DocumentTable: React.FC<Props> = ({ className, documentData, type, setRefresh, preview, toggleState, setAudioFile, setFileName, setTranscription, onReview, setSelectedFiles, handleDownload }) => {
    const [data, setData] = useState<DocumentDataObject[]>([])
    const [teams, setTeams] = useState<string[]>()
    const [selectedFilesTable, setSelectedFilesTable] = useState<string[]>([]);
    const [selectedFileNames, setSelectedFileNames] = useState<string[]>([]);
    // const [fileName, setFileName] = useState<string>("");
    const [fileLink, setFileLink] = useState<string>()
    const [feedback, setFeedback] = useState<string>()
    const [teamMember, setTeamMember] = useState<string>()
    const [processingFile, setProcessingFile] = useState<string>()
    const [processing, setProcessing] = useState<boolean>(false)
    const [downloadFormat, setDownloadFormat] = useState('csv')
    const [showDropdown, setShowDropdown] = useState<string>(); // State to manage dropdown visibility
    const [showFeedback, setShowFeedback] = useState('');

    const toggleDropdown = (uploaded_file_url: string) => {
        setShowDropdown(showDropdown === uploaded_file_url ? '' : uploaded_file_url); // Toggles the dropdown for the corresponding uploaded_file_url
    };

    const handleButtonClick = (uploaded_file_url: string) => {
        toggleDropdown(uploaded_file_url); // Toggle the dropdown visibility
    };

    const handleOptionClick = () => {
        setShowDropdown(""); // Close the dropdown when an option is clicked
    };

    const { currentUser } = useAuth();

  

    const handleSelect = (
        e: any,
        url: string,
        name: string,
        processed: number
    ) => {
        console.log(e.target.checked, url);

        if (e.target.checked) {
            let tempSelectedFileNames = selectedFileNames;
            tempSelectedFileNames.push(name);
            setSelectedFileNames([...tempSelectedFileNames]);
            selectedFilesTable.push(url);
            setFileName(name);
            // if (processed == 1 && selectedFilesTable.length <= 1) {
            //   handleProcess();
            // }
        }
        if (!e.target.checked) {
            setFileName("");
            // setResult({});
            const index = selectedFilesTable.indexOf(url);
            if (index > -1) {
                selectedFilesTable.splice(index, 1);
                let tempSelectedFileNames = selectedFileNames;
                tempSelectedFileNames.splice(index, 1);
                setSelectedFileNames([...tempSelectedFileNames]);
            }
        }
        setSelectedFiles(selectedFilesTable)
    };

    const handleToggle = () => {
        toggleState(); // Toggle the state in the parent component
    };

    // useEffect(() => {
    //     getAllMember({ organizationId: currentUser?.organizationId }).then((res) => {
    //         //  Filtering out emails of objects with type !== 'superadmin'
    //         const emails = res.data
    //             .filter((user: TeamMemberObject) => user.type !== 'superadmin')
    //             .map((user: TeamMemberObject) => user.email);

    //         setTeams(emails)
    //     });
    // }, [])

    return (
        <div>
            {/* Feedback Modal */}
            <div
                className="modal fade"
                id="kt_modal_show_feedback"
                aria-hidden="true"
            >
                {/* begin::Modal dialog */}
                <div className="modal-dialog modal-dialog-centered mw-650px">
                    {/* begin::Modal content */}
                    <div className="modal-content">
                        <div className="modal-header">
                            {/* begin::Modal title */}
                            <h2 className="fw-bolder">Document Feedback</h2>
                            {/* end::Modal title */}

                            {/* begin::Close */}
                            <button
                                className="btn btn-sm btn-icon btn-active-color-primary"
                                data-bs-dismiss="modal"
                                type='button'
                            >
                                <KTIcon iconName="cross" className="fs-1" />
                            </button>
                            {/* end::Close */}
                        </div>
                        {/* begin::Modal body */}
                        <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
                            {
                                (showFeedback == '' || showFeedback == null) ? <p className="fs-1 fw-bold text-center">No Feedback Provided</p> :
                                    <p className="fs-4">{showFeedback}</p>
                            }
                        </div>
                        {/* end::Modal body */}
                    </div>
                    {/* end::Modal content */}
                </div>
                {/* end::Modal dialog */}
            </div>

            <div className={`card ${className}`} >
                {/* end::Header */}
                {/* begin::Body */}
                <div className='card-body py-3'>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                        {/* begin::Table */}
                        <table className='table align-middle gs-0 gy-4'>
                            {/* begin::Table head */}
                            <thead className='position-sticky top-0'>
                                <tr className='fw-bold text-muted bg-light'>
                                    <th className='ps-4 min-w-50px rounded-start'></th>
                                    <th className='min-w-100px'>Documents</th>
                                    <th className='min-w-200px'>Created At</th>
                                    <th className='min-w-200px'>Assign To</th>
                                    <th className='min-w-150px'>Status</th>
                                    <th className='min-w-150px'>Approval</th>
                                    <th className='min-w-250px text-end rounded-end pe-6'>Action</th>
                                </tr>
                            </thead>
                            {/* end::Table head */}
                            {/* begin::Table body */}
                            <tbody>
                                {
                                    documentData.length > 0 && documentData.map((doc, index) => (
                                        <tr key={index}>
                                            <td className='ps-4'>
                                                <input
                                                    className="form-check-input bg-light border-4 border-gray-400 border-start border-top"
                                                    type="checkbox"
                                                    value=""
                                                    checked={selectedFilesTable.includes(doc.uploaded_file_url)}
                                                    onClick={(e) =>
                                                        handleSelect(
                                                            e,
                                                            doc.uploaded_file_url,
                                                            doc.original_file_name,
                                                            doc.processed
                                                        )
                                                    }
                                                />
                                            </td>
                                            <td>
                                                <div className='d-flex align-items-center justify-content-start'>
                                                    {(processing && (fileLink && fileLink == doc.uploaded_file_url)) ?
                                                        <span className="indicator-progress me-5" style={{ display: "block" }}>
                                                            {/* Please wait...{" "} */}
                                                            <span className="spinner-border align-middle ms-2 fs-3x"></span>
                                                        </span> :
                                                        <div className='symbol symbol-50px me-5'>
                                                            <KTIcon iconName='file' className="fs-3x text-dark" />
                                                        </div>
                                                    }
                                                    <div className='d-flex justify-content-start flex-column'>
                                                        <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                                                            {(doc.original_file_name.lastIndexOf('.') > -1 && doc.original_file_name.lastIndexOf('.') <= 20) ? doc.original_file_name.substring(0, doc.original_file_name.lastIndexOf('.')) : doc.original_file_name.substring(0, 20)}

                                                        </a>
                                                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                                            {doc.tag}
                                                        </span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                    {new Date(doc.upload_date).toLocaleString()}
                                                </a>
                                                {/* <span className='text-muted fw-semibold text-muted d-block fs-7'>Paid</span> */}
                                            </td>
                                            <td>
                                                {doc.assign_to == "" ?
                                                    <a href='#' className='text-center text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                        Not Assigned
                                                    </a> :
                                                    <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                        {doc.assign_to}
                                                    </a>
                                                }
                                                {/* <span className='text-muted fw-semibold text-muted d-block fs-7'>Insurance</span> */}
                                            </td>
                                            <td>
                                                {
                                                    doc.processed == 1 ?
                                                        <span className='badge badge-light-success'>Proccessed</span>
                                                        : <span className='badge badge-light-danger'>Unprocessed</span>
                                                }
                                            </td>
                                            <td>
                                                {
                                                    doc.approval == "pending" || doc.approval == "" || doc.approval == null ?
                                                        <span className='badge badge-light-warning'>Pending</span>
                                                        : doc.approval == "accepted" ?
                                                            <span className='badge badge-light-success'>Accepted</span>
                                                            : <span className='badge badge-light-danger'>Rejected</span>
                                                }
                                            </td>
                                            <td className='text-end'>
                                                {/* {
                                                    doc.processed === 0 &&
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary me-3 my-3 w-180px "
                                                    >
                                                        Process
                                                    </button>
                                                } */}
                                                <button
                                                    type="button"
                                                    className="btn btn-primary btn-sm me-3 my-3 w-100px fs-8"
                                                    onClick={(e) => {
                                                        setFileLink(doc.uploaded_file_url);
                                                        setAudioFile(doc.uploaded_file_url);
                                                        setFileName(doc.original_file_name);
                                                        handleToggle();
                                                        onReview(doc.uploaded_file_url,doc.approval)
                                                    }}
                                                >
                                                    {doc.processed == 1 ? "Review" : "Process"}
                                                </button>

                                                {
                                                    doc.processed == 1 && <>
                                                        {/* )} */}
                                                        <button
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#kt_modal_show_feedback"
                                                            onClick={(e) => {
                                                                setShowFeedback(doc.feedback)
                                                            }}
                                                            type='button'
                                                            disabled={doc.feedback == null ? true : false}
                                                            className={`btn btn-icon btn-icon-muted ${(doc.feedback === null || doc.feedback == '') ? 'btn-secondary' : 'btn-warning'} btn-sm w-120px fs-8 mx-1`}
                                                        >
                                                            <KTIcon iconName='information' className='fs-4 fw-bolder' />
                                                        </button>
                                                    </>
                                                }
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                            {/* end::Table body */}
                        </table>
                        {/* end::Table */}
                    </div>
                    {/* end::Table container */}
                </div>
                {/* begin::Body */}
            </div>
        </div>
    )
}

export { DocumentTable }

